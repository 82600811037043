@import 'variables';

.feeling-word-actions-list {
  margin-bottom: 0;

  .action-container {
    background: $white;
    border: 1px solid $light-gray;
    border-radius: $global-radius;
    margin: 0 0 .5rem;
    padding: .25rem;

    .action-label {
      font-weight: bold;
      margin: .5rem 0;
    }

    select, input {
      margin-bottom: .5rem;
    }

    .action-panel {
      margin-right: 0;

      .action-row {
        padding-right: .25rem;

        .action-record {
          margin-right: .25rem;

          input {
            height: 2.25rem;
          }
        }

        .delete-action {
          margin-left: 0;

          button {
            cursor: pointer;
            padding: .25rem 0;
            width: 2rem;
            font-size: 1.75rem;
            color: $dark-gray;

            &:hover {
              color: $black;
            }
          }
        }
      }
    }
  }
}

.actions-label {
  display: block;
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.8;
  color: #000;

  p {
    margin-bottom: .5rem;
  }
}
