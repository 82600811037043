@import 'variables';

.assessment-questions-container {
  padding-bottom: 2rem;
}

.assessment-score {
  border: 1px solid $light-gray;
  padding: 1.5rem;
  margin: 0 0 3rem;

  &-text {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .score {
    font-size: 3rem;
    font-weight: 600;
  }
}
