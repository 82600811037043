@import 'variables';
@import 'mixins';

// charter details styles

.charter-toolbar {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  .button {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 1rem;
    margin: 0 1rem 1rem 0;
    font-size: rem-calc(12);
    font-weight: 600;
    border-width: 2px;
    letter-spacing: .025rem;
    text-transform: uppercase;
    color: $medium-gray;

    &:hover, &:focus {
      color: $blue;
    }

    .text {
      margin-right: 10px;
    }

    .icon {
      font-size: 1rem;
    }

    .icon-share {
      font-size: 1.2rem;
    }

    &.share-charter {
      padding: 0;
    }

    &.pdf-charter .icon-pdf {
      margin-top: 3px;
    }

    &.edit-charter {
      color: $blue;

      &:hover {
        color: $alert-color;
        border-color: $alert-color;
      }
    }
  }
}

.breadcrumb-container {
  background: #1e2749;
  padding: .7rem 0;
  color: $white;

  .back-link {
    font-size: .875rem;
    color: inherit;

    i {
      font-size: 1.25rem;
      position: relative;
      vertical-align: middle;
      top: 2px;
    }
  }
}

// end of charter details styles

// charter preview styles

.charter-single-container {
  margin-bottom: 2rem;
}

.question-intro-text ~ .preview {
  margin-top: 2.5rem;
}

.preview {
  background-size: cover;
  font-family: $body-font-family;
  width: 300px;
  height: 425px;
  margin: 0 auto;
  position: relative;
  @include breakpoint(medium) {
    width: 595px;
    height: 842px;
  }

  .charter-single-container & {
    margin: 0;
  }
}

.charter-background-4 {

  .preview-content {
    color: $white;

    .editable .content {
      white-space: pre-wrap;
    }
  }

  .charter-question-label {
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .powered-by-ruler {
    color: white;
  }
}

.charter-background-2 {

  .preview-content {
    padding: 90px;
  }

  .charter-title {
    padding-top: 2rem;
  }

  .powered-by-ruler {
    bottom: 85px;
  }
}

.charter-background-5 {

  .preview-content {
    padding-right: 180px;
  }
}

.charter-background-6 {

  .preview-content {
    padding: 60px 90px;
  }
}

.charter-background-8 {

  .preview-content {
    padding-left: 90px;
  }
}

.charter-background-10 {

  .preview-content {
    padding: 90px;
  }

  .preview-content, .charter-title, .charter-question-label {
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .editable {
    font-style: italic;
  }
}

.preview-image {
  position: absolute;
}

.preview-content {
  padding: 60px;
  text-align: left;
  position: absolute;
  width: 100%;
  height: 100%;

  .charter-title {
    font-size: 2.5rem;
    font-weight: normal;
    margin-bottom: 3rem;
  }

  .charter-question-label {
    font-size: 1.5rem;
    line-height: 1.25;
  }

  .action-group {
    padding-top: 1rem;
  }

  .editable {
    margin-bottom: 2rem;
  }
  @include breakpoint(medium down) {
    padding: 30px;

    .charter-title {
      font-size: 1.75rem;
    }

    .charter-question-label {
      font-size: 1.25rem;
    }
  }
}

.powered-by-ruler {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  line-height: 1;
  position: absolute;
  bottom: 32px;
  margin: 0;
  left: 0;
}

#charter-preview .content {
  white-space: pre-wrap;
}

// end of charter preview styles

// staff charter styles

.charter-container {
  font-family: $body-font-family;
  text-align: center;

  .charter-template {
    margin: 0 auto;
    min-width: 20rem;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    background-position: top, bottom, 0 100px;
    background-size: contain, contain, contain;
    background-repeat: no-repeat, no-repeat, repeat-y;
    background-clip: border-box, border-box, content-box;
    background-image: url('/static/staff-charter/charter-scroll-top.svg'),
      url('/static/staff-charter/charter-scroll-bot.svg'),
      url('/static/staff-charter/charter-scroll-mid.svg');
  }

  .charter-header {
    text-align: center;
    padding: 13% 10% .5rem;

    h3 {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .charter-logo {
      max-width: 7.5rem;
      margin: 0 auto;
      padding-bottom: 1rem;

      svg {

        #school-logo-path {
          fill: #77797d;
        }
      }
    }
  }

  .charter-toolbar {
    margin-left: 1rem;
    margin-bottom: 0;
  }

  .charter-body {
    text-align: center;
    font-size: 1.2rem;
    padding-left: 11%;
    padding-right: 11%;

    .charter-subheader {
      padding-top: 1rem;

      &:first-child {
        padding-top: 0;
      }
    }

    .want-to-feel {

      &-words {
        margin-bottom: 2rem;

        span {
          font-size: 2rem;
          font-weight: 600;
        }
      }

      &-actions .action-group {
        margin: 1rem 0;
      }
    }
  }

  .charter-footer {
    padding-top: calc(15% - 1rem);
    padding-bottom: calc(15% - 6rem);
    font-size: .75rem;
    color: #775526;
    text-align: center;
  }
}

.staff-charter-modal .modal-container {
  padding-top: 2rem;
}

.render-warning {
  color: $medium-gray;
}

.emotion-words-filter-container {
  height: auto;
  max-height: 0;
  overflow: hidden;
  transition: all .3s ease;

  // A downside for animating max-height is that the transition length will not actually be what you specify unless the content height works out to be exactly the same as max-height. In most casess this means that the actual transition time will be less than the specified one. Hence the increase to .3s (from .25s).

  &.opened {
    max-height: 150px;
  }
}

.emotion-words-filter {
  display: flex;
  padding: 1rem;
  flex-flow: row;
  flex-wrap: wrap;
  background-color: lighten($light-gray, 10%);

  .emotion-word {
    margin: .25rem;
    padding: 3px 10px;
    border: 2px solid $medium-gray;
    border-radius: 4px;
    cursor: pointer;
    background-color: lighten($light-gray, 5%);
    transition: all .25s ease;

    &:hover {
      background-color: lighten($light-gray, 10%);
    }

    &.selected {
      background-color: lighten($light-gray, 15%);

      &:hover {
        background-color: lighten($light-gray, 17%);
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

// end of staff charter styles
