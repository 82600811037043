@import 'variables';
@import 'mixins';

.showcase-heading {
  margin-bottom: 1rem;

  .listing-hint {
    padding-top: .6rem;
    padding-bottom: .4rem;
  }

  .listing-action {

    button {
      margin-bottom: 0;
    }
  }
}

@keyframes delayed-loader {

  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.showcase-listing {
  position: relative;

  .loading-bar {
    background-color: rgba($color: $white, $alpha: .8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    user-select: none;
    text-align: center;
    animation: delayed-loader 1s linear;

    svg {
      width: 12rem;
      height: 12rem;
    }
  }
}
