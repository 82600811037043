.showcase-filters {

  .filter-menu.search-menu {

    .close-button.small {
      top: .35rem;
      right: .75rem;
      font-size: 2rem;
    }

    .autocomplete-input.search-input {
      height: initial;
    }
  }
}
