@import 'variables';
@import 'mixins';

$header-height: 82px;

.top-bar {
  @include xy-grid-container();
  padding-top: 0;
  padding-bottom: 0;
  align-items: stretch;

  .top-bar-left {
    flex: 0 1 auto;

    .menu-button {
      margin-right: 10px;
    }

    .logo {
      display: block;
      padding: 1.25rem 0;
      max-width: 120px;
      position: relative;
      @include breakpoint(medium) {
        max-width: 170px;
      }

      img {
        display: block;
      }

      .beta {
        background: red;
        color: white;
        padding: 2px 3px 1px;
        display: block;
        font-size: 8px;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 1;
        position: absolute;
        right: 0;
        bottom: -9px;
        text-align: center;
      }
    }
  }

  .top-bar-center {
    flex: 1 0 auto;
  }

  .top-bar-right {
    flex: 0 1 auto;
    position: relative;
    display: flex;
    align-items: center;

    .dropdown-pane {
      top: $header-height;
      right: 0;
      padding: .5rem;
    }

    .notifications-panel {

      button#menuButtonNotifications {
        padding: .85rem 1em;
        margin-bottom: 0;

        .inner {
          padding: 9px;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          overflow: hidden;
          position: relative;

          &:hover {
            background-color: $bg-gray;
          }

          svg {
            fill: darken(#036cb6, 10%);
          }

          .alert-counter {
            padding: 3px;
            font-size: 12px;

            display: inline-flex;
            align-items: center;
            justify-content: center;

            min-width: 14px;
            height: 14px;
            vertical-align: middle;
            border-radius: 50%;

            background-color: $ruler-red;
            color: $white;

            position: absolute;
            top: 6px;
            right: 5px;

            &.small {
              min-width: 10px;
              height: 10px;
              padding: 0;
              top: 8px;
              right: 7px;
            }
          }
        }
      }

      &.opened {

        button#menuButtonNotifications .inner {
          background-color: $bg-gray;
        }
      }

      .dropdown-pane {
        padding: 0;

        .notification-header, .notification-footer {
          padding: .5rem;
          font-size: 14px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: stretch;
          align-content: stretch;

          a:hover {
            text-decoration: underline;
          }
        }

        .notification-footer {
          justify-content: space-around;
          padding: .2rem;
        }

        ul.notification-list {
          box-shadow: inset 0 1px 6px rgba(0, 0, 0, .15);
          list-style: none;
          margin: 0;
          padding: 0;
          max-height: 65vh;
          overflow: auto;

          .list-item {
            cursor: pointer;
            padding: .5rem;
            border-top: 1px solid $bg-gray;
            box-sizing: content-box;
            transition: all .3s ease;

            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: stretch;
            align-content: stretch;

            &.new {
              background-color: #edf2fa;

              .dot {
                border-radius: 50%;
                width: 6px;
                height: 6px;
                display: inline-block;
                background-color: $medium-gray;
              }

              &:hover {

                .dot {
                  background-color: $dark-gray;
                }
              }
            }

            &:hover {
              box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
              background-image: linear-gradient(rgba(29, 33, 41, .04), rgba(29, 33, 41, .04));
            }

            .button {
              // All notification buttons
              padding: .5rem;
              margin: 0;
            }

            p {
              margin: 0;
              padding: 0;
              line-height: inherit;
            }

            i {
              font-size: .65rem;
              margin-right: .25rem;
            }

            .ruler-loading {
              height: 36px;
              width: 36px;
            }

            .notification-image {
              margin-top: .25rem;
              margin-right: .5rem;
            }
          }
        }
      }
    }

    .user-panel {

      button {
        margin-bottom: 0;
      }

      .user-avatar {
        border-radius: 50%;
        overflow: hidden;

        width: 40px;
        height: 40px;

        img {
          height: inherit;
        }
      }
    }
  }
}

.user-nav {
  padding-top: 4.25rem;

  .user-profile {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    padding-top: 0;
  }

  .user-avatar, .user-name {
    display: block;
    text-align: center;
  }

  .user-avatar {
    margin: 0 auto .75rem;
    border-radius: 50%;
    border: 1px solid $light-gray;
    width: 66px;
    height: 66px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-button {
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid $light-gray;
  }

  &-avatar {
    display: block;
  }
}

.user-nav, .user-nav-links, .user-nav-footer {

  li {

    > a {
      color: $black;
      font-weight: 500;
      padding: .75rem 1.5rem;

      &:hover {
        background: $bg-gray;
        color: $blue;
      }
    }
  }
}

.user-nav-footer {
  padding-top: 3rem;
}

#menu-main-navigation {
  position: static; // set to default value

  > li {

    &.active {

      > a {
        color: lighten($primary-color, 5%);
      }
    }

    .dropdown-pane {
      position: absolute;
      top: $header-height;
      left: 0;
      right: 0;
      width: 100vw;
      max-width: 100%;
      padding: 1rem 0 .75rem;
      border: 1px solid #c9d3df;
      border-left: none;
      border-right: none;
      border-radius: 0;
      background-color: #fff;
      font-size: 1rem;

      .cell {
        display: flex;
      }

      .title > a {
        color: inherit;

        h3 {
          font-size: 1rem;
          font-weight: 600;
          display: inline-block;
        }
      }
    }

    .card {
      position: relative;
      text-align: center;

      > a {
        color: $black;
      }

      &:hover {
        background: $white;
        box-shadow: 0 0 6px 1px $bg-gray;
      }

      &.inactive {
        background: $bg-gray;
      }

      &.curriculum {

        .card-section {
          padding: .5rem;
        }

        .card-icon {
          padding: 0 0 .5rem;
        }
      }

      &.tools {
        padding: .7rem .5rem;

        > a {
          padding: 0;
        }

        .card-section {
          padding-top: .5rem;
          padding-bottom: .5rem;
        }

        .card-icon img {
          width: 88px;
          height: 88px;
        }
      }

      a {
        color: $black;
        padding: .25rem;

        .card-title {
          font-size: .875rem;
          font-weight: 700;
          padding-top: .25rem;
        }
      }

      .corner-ribbon {
        width: 160px;
        line-height: 40px;
        font-size: .6rem;
      }

      .corner-ribbon.top-right {
        top: 12px;
      }
    }

    > a {
      color: $cloudburst;
      font-size: rem-calc(16);
      font-weight: 600;
      padding: 2rem 1rem;

      &:hover {
        color: lighten($primary-color, 5%);
      }

      &:before {
        font-size: 1.5rem;
        position: relative;
        margin-right: 6px;
        vertical-align: middle;
      }
    }
  }
  @include breakpoint(medium) {

    > li {

      > a {
        text-align: center;

        &:before {
          display: block !important;
          margin-bottom: 3px;
          margin-right: 0;
        }
      }
    }
  }
  @include breakpoint(460px down) {

    > li {

      > a {

        span {
          display: none;
        }

        &:before {
          top: 0;
        }
      }
    }
  }

  .dropdown.menu > li.is-dropdown-submenu-parent > a:after {
    right: 12px;
  }

  .mega-dropdown {

    > a {
      color: $black;
      position: relative;

      @include icon(after, down-chevron);

      &:after {
        line-height: 1;
        display: inline-block;
        font-style: normal;
        font-weight: 400;
        font-size: 1.25rem;
        top: 50%;
        position: absolute;
        margin-top: -.65rem;
      }
    }

    &.opened {

      > a {
        color: $blue;

        &:before {
          content: '';
          position: absolute;
          height: 2px;
          background: $blue;
          left: 1rem;
          right: 0;
          bottom: -5px;
        }

        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
}

#menu-main-navigation .is-dropdown-submenu a {
  text-transform: none;
  font-size: rem-calc(13);
  font-weight: normal;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent.active > a {
  background: transparent;
  border: 1px solid $primary-color;
  color: $primary-color;

  &:after {
    border-top-color: $primary-color;
  }
}

.grid-container-full {
  border-bottom: 1px solid $light-gray;
  margin-bottom: 2rem;
  @include breakpoint(small only) {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .is-stuck & {
    margin-bottom: 0;
  }

  .menu.tabs {
    flex: 1 0 auto;
    flex-wrap: nowrap;
    @include breakpoint(small only) {
      justify-content: flex-start;

      .tabs-title {
        flex: 1 0 auto;
      }
    }
  }
}

.tabs-panel {
  position: relative;
  padding-top: 2rem;

  > h2 {
    font-size: 1.5rem;
    margin: 0 0 1.5rem;
    font-weight: 600;
  }
}

.menu.tabs {
  display: flex;

  .tabs-title {
    float: none;

    > a {
      font-weight: 600;
      font-size: rem-calc(12);
      text-transform: uppercase;
      letter-spacing: .08em;
      line-height: 1.5;
      @include breakpoint(medium down) {
        padding: 1.5rem 1rem;
        letter-spacing: normal;
      }
    }
  }

  .is-active > a {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 1.5rem;
      right: 1.5rem;
      height: 3px;
      background: $blue;
      @include breakpoint(medium down) {
        left: 1rem;
        right: 1rem;
      }
    }
  }
}

.menu {

  &.filter {

    li > a {
      color: $black;
      font-weight: 600;
    }

    .is-active {

      a {
        position: relative;
        color: $blue;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: $blue;
          bottom: -5px;
          left: 0;
        }
      }
    }
  }
}

.footer-nav {

  .menu.align-right {
    @include breakpoint(medium down) {
      justify-content: flex-start;
      margin-bottom: 1.5rem;
    }
  }
}
