@import 'variables';
@import 'mixins';

.hero-section.hero-section-cm-units.taxonomy {
  @media print {

    a[href]:after {
      content: none !important;
    }
  }

  h2.cm-units-subtitle {
    text-transform: uppercase;
    font-size: 1rem;

    @media print {
      font-size: 2rem;
      text-align: center;
    }
  }

  h1.page-title {
    margin-top: 0;

    @media print {
      font-size: 2rem;
      text-align: center;
    }
  }

  .cm-unit-description {
    padding: 2rem 0;

    p {
      margin-bottom: 0;
    }

    &.cm-single-unit {

      h1 {
        margin-bottom: 0;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;

  .grid-container {
    width: 100%;
  }
}

.last-course.card {
  background-color: $bg-gray;

  .card-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.units {
  list-style-type: none;
  margin: 0;
  padding: 1rem 0;
  margin-bottom: 3rem;

  .unit {

    .unit-title {
      color: $cloudburst;
      width: 100%;
      border-bottom: 1px solid $light-gray;
      padding: 1.5rem 0 .5rem;
      @include icon(after, down-chevron);
      font-size: 1.75rem;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &.unit-closed {
        @include icon(after, right-chevron);
      }

      span {
        text-transform: uppercase;
      }
    }

    .unit-lessons {
      list-style-type: none;
      margin: 0;
      padding: 0;

      .unit-lesson {
        border-bottom: 1px solid $light-gray;
        padding: .5rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {

          &.unit-lesson-title {
            color: $cloudburst;
          }

          strong {
            text-transform: uppercase;
          }
        }

        .button.success.small {
          padding-top: .5rem;
          padding-bottom: .5rem;
          margin: 0;
        }
      }
    }
  }
}
