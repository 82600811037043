@import 'variables';

.feature-container {
  position: fixed;
  display: none;
  height: 100%;
  left: 0;
  top: 0;
  align-items: top;
  justify-content: center;
  overflow: hidden;
  min-height: 600px;
  @include breakpoint(large) {
    display: flex;
    width: 460px;
  }

  &-cover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-size: cover;
    background-position: right center;

    &:before {
      content: '';
      background: rgba(6, 34, 77, .7);
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    color: $white;
    max-width: 80%;
    padding-top: 90px;

    h2 {
      font-size: rem-calc(32);
      font-weight: 300;
      line-height: 1.35;
    }
  }
}
