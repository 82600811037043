@import 'variables';

.button.button-pin {
  width: 35px;
  height: 35px;
  color: $ruler-blue;
  background-color: $white;
  padding: 0;
  top: .75rem;
  right: .75rem;
  position: absolute;
  line-height: 35px;
  border-width: 2px;
  border-radius: 50%;
  border-color: $light-gray;
  transition: all .2s ease-in-out;

  &:hover, &:focus {
    color: $ruler-blue;
    border-color: $light-gray;
    background-color: $bg-gray;
  }

  &.is-active {
    color: $ruler-red;
    border-color: $ruler-red;
  }
}

.admin-page.hero-section .button.button-pin {
  position: inherit;
  margin: 0;
  margin-left: .5rem;
  border: none;
  background: none;

  &:hover {
    color: lighten($ruler-blue, 20%);

    &.is-active {
      color: darken($ruler-red, 10%);
    }
  }
}
