@import 'variables';

button.button.close-survey-button, button.button.create-survey-button {
  color: $white;
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    color: $white;
  }
}
