@import 'variables';

.bar-container {
  border: 1px solid #d7dde5;
}

.filters-container {
  background-color: #eceff3;
}

.filters {
  display: inline-block;
}

.filters-container select {
  border: none;
  background-color: #eceff3;
  margin: 0;
}
