@import 'variables';
@import 'mixins';
@import 'audio-transcript';

.module-page {

  .button-container {
    width: 100%;
    text-align: center;
  }

  .module-section {
    padding-bottom: 0rem;
    padding-top: 2rem;

    &.rulerize {
      min-height: calc(100vh - 125px);
    }
  }

  .module-intro, .module-outro {
    //min-height: calc(100vh - 120px);

    .align-center-middle {
      min-height: calc(100vh - 120px);
    }

    @include breakpoint(large) {
      width: 80%;
      margin: 0 auto;
    }
  }

  .module-title {
    margin-bottom: 5rem;
    font-size: rem-calc(48);
    font-weight: 600;
    line-height: 1.2;

    span {
      display: block;
      text-transform: uppercase;
      font-size: .5em;
      font-weight: 400;
    }
  }

  #module-end {
    position: relative;

    &.fullscreen {
      min-height: calc(100vh - 120px);
      padding: 0;
    }

    & .fullscreen {
      min-height: calc(100vh - 120px);
    }
  }

  .module-end-content {
    background: $white;
    border: 1px solid $light-gray;
    padding: 1.5rem;
    font-size: rem-calc(18);
    font-weight: 300;
    text-align: center;

    .module-end-text {
      text-align: left;
    }

    ul {

      li {
        margin-bottom: 1rem;
        line-height: 1.3;
      }
    }

    .section-title {

      &:before {
        position: static;
      }
    }
  }

  .module-objective {
    text-transform: uppercase;
    font-size: rem-calc(20);
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 1.5rem;

    &-list {
      position: relative;
      margin-bottom: 4rem;

      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 6px;
        float: left;
        background: $light-gray;
      }

      li {
        padding-left: 1.5rem;
        padding-bottom: 1.5rem;
        font-size: rem-calc(20);
        line-height: 1.1;

        &:last-child {
          padding-bottom: 0;
        }

        strong {
          text-transform: uppercase;
          display: block;
        }
      }
    }
  }

  .fullscreen {
    min-height: calc(100vh - 100px);
  }

  .module-step {
    margin-bottom: 2rem;
  }

  .section-title {
    font-size: rem-calc(42);
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    position: relative;
  }

  .section-text {
    margin-bottom: 1.5rem;
    font-size: rem-calc(20);
    font-weight: 300;

    a {
      text-decoration: underline;
    }

    blockquote {
      position: relative;
      font-style: italic;
      padding-top: 0;

      &:before {
        content: '';
        background: $medium-gray;
        position: absolute;
        left: 0;
        top: 6px;
        bottom: 6px;
        width: 4px;
      }
    }
  }

  .section-image {
    margin-bottom: 3rem;
  }

  .section-quote {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
    border: 1px solid $light-gray;

    // Try forcing the browser to print the background image
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */

    blockquote {
      font-family: 'Times New Roman', Times, serif;
      font-size: rem-calc(25);
      font-style: italic;
      position: relative;

      &:before {
        content: '\201C';
        font-family: Georgia, serif;
        font-size: 40px;
        font-weight: bold;
        color: $black;
        position: absolute;
        left: -10px;
        top: 0;
      }

      footer {
        font-size: rem-calc(16);
        font-family: $body-font-family;
        font-style: normal;
        font-weight: 600;
        color: $black;
        text-transform: uppercase;
      }
    }

    .quote-text {
      background: rgba($white, .8);
      padding: 1.5rem 0;
      @include breakpoint(medium) {
        padding: 3.5rem 0;
      }
      @include breakpoint(large) {
        min-height: 450px;
      }
    }
  }

  .activity-container, .feedback-container {
    background: $bg-gray;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    padding: 3rem 0;
    margin: 3rem 0 4rem;
  }

  .activity-title, .rulerize-title {
    font-size: rem-calc(32);
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 1rem;
  }

  .feedback-form, .activity-form {

    label {
      font-size: rem-calc(18);
      font-weight: 600;
      margin-bottom: 8px;
    }

    textarea {
      min-height: 100px;
      word-wrap: break-word;
      margin-bottom: 0;
    }
  }

  .activity-question-form + .activity-question-form {
    margin-top: 3rem;
  }

  .checkpoint-form-wrapper {

    legend {
      font-size: rem-calc(24);
      line-height: 1.2;
      margin-bottom: 1.5rem;
    }

    .help-text {
      color: $medium-gray;
      font-size: rem-calc(16);
      margin-bottom: .75rem;
      font-weight: 300;
    }
  }

  .activity-container {
    font-size: rem-calc(20);
    font-weight: 300;

    .plyr {
      margin-bottom: 0;
      border: 1px solid $light-gray;
    }
  }

  .activity-question-title {
    font-size: rem-calc(24);
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .activity-intro {
    margin-bottom: 3rem;

    .activity-intro-title {
      font-weight: 600;
      line-height: 1.2;
    }

    .activity-intro-label {
      font-size: rem-calc(42);
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: .5rem;

      & ~ p {
        margin-bottom: 1.5rem;

        &.activity-time {
          font-size: 1rem;
          letter-spacing: .05em;
          margin-bottom: 2rem;
          text-transform: uppercase;
          position: relative;
        }
      }
    }

    .countdown-timer {
      position: absolute;
      right: 0;
      top: 0;

      time {
        @include icon(after, timer-button);
        color: $black;
        font-size: rem-calc(18);
        padding-right: .25rem;

        &:after {
          position: relative;
          top: 2px;
          left: 6px;
        }
      }
    }

    .button.start {
      background: $primary-color;
      text-transform: uppercase;
      font-weight: bold;
      padding: .85rem 2.5rem;
      letter-spacing: .05em;

      &:hover, &:focus {
        background-color: $blue;
      }

      i {
        margin-right: 10px;
        font-size: rem-calc(18);
        position: relative;
        top: 3px;
      }
    }
  }

  .checkpoint-audio {
    padding: 0;
    margin-bottom: 3rem;
  }

  .check-form {

    fieldset {
      margin-bottom: 2rem;
    }
  }

  .gallery {
    margin-bottom: 3rem;
  }

  .gallery-item {
    padding: 2px;

    img {
      border: 1px solid $medium-gray;
    }
  }

  .rulerize-form {
    margin: 1.5rem 0 3rem;

    .button {
      margin: 1rem 0 0;
    }
  }

  #canvas {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: -1;
  }

  .checkpoint-audio-modal {
    background: $bg-gray;
    transition-duration: .25s !important;
    padding: 0;
  }

  .text-image {
    margin: 3rem auto;
  }
}
