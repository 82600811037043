.card.card-cm-category {
  position: relative;

  .card-section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    p {
      margin-bottom: 0;
    }
  }
}
