@import 'variables';

.journal-nav {
  padding: 1.5rem 0;
  @include breakpoint(small only) {
    padding-top: 3rem;
  }
}

.journal-card.card {
  border-left-width: 5px;
  position: relative;

  .card-title {
    padding-right: 2rem;
  }

  .byline, .journal-cat {
    color: $dark-gray;
    margin: 0;
  }

  .journal-cat {
    font-size: rem-calc(16);

    i {
      position: relative;
      top: 2px;
    }
  }

  .note-content {
    position: relative;
    overflow: hidden;
    max-height: 100px;

    p {
      margin-bottom: .5rem;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -6px;
      height: 20px;
      width: 100%;
      background: #fff;
      background: linear-gradient(rgba($white, .3), $white);
    }
  }
}

.note-title {
  font-size: rem-calc(25);
  line-height: 1.15;
}

.reveal.note {

  .modal-container.narrow {
    padding: 1rem 2rem 0 0;
  }
}

.editor-container {
  height: auto;
  min-height: 200px;

  .ql-editor {
    overflow-y: visible;

    p {
      font-size: 1rem;
      font-family: $body-font-family;
      margin-bottom: 1rem;
    }
  }
}

/* Specify our own scrolling container */

.scroll-container {
  height: 100%;
  min-height: 100%;
  overflow-y: auto;

  .ql-toolbar.ql-snow, .ql-container.ql-snow {
    border-color: $light-gray;
  }
}
