@import 'variables';
@import 'mixins';

.tools-list > .cell {
  display: flex;
}

.tools-list {
  margin-bottom: 3rem;
}

.tools-section-title {
  font-size: rem-calc(30);
  font-weight: 600;
  margin-bottom: 1rem;
}

.tools-card {
  display: flex;

  .card-icon {
    padding: 2rem 5rem 1rem;
  }

  &.card {
    background: $white;
    position: relative;
    transition: .1s;

    &:hover, &:focus, &:active {
      background: $white;
      box-shadow: 0 0 6px 1px $bg-gray;
    }

    &.inactive {
      background: $bg-gray;

      &:hover, &:focus, &:active {
        background: $bg-gray;
        box-shadow: none;
      }
    }

    .card-section {
      flex: 0 0 auto;
    }

    .card-title {
      font-size: rem-calc(24);
      font-weight: bold;
      text-align: center;
      color: $cloudburst;

      & + p {
        text-align: center;
        padding-bottom: 1rem;
        font-weight: 300;
        line-height: 1.35;
      }
    }

    a {
      color: inherit;
    }
  }
}
