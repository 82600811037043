@import 'variables';
@import 'mixins';

.reveal.showcase-details {
  padding: 0 !important;

  .showcase-modal {

    .modal-container {

      .media {
        min-height: 60vh;
        max-height: 80vh;
      }
    }

    .media-container {

      .ruler-loading {
        height: 20rem;
        padding: 5rem;
      }

      .media-content {
        display: block;
        width: 100%;

        @include breakpoint(small) {
          height: 50vh;
        }
        @include breakpoint(large) {
          padding-left: 1rem;
          height: 70vh;
        }

        &.image {
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }

        &.video {
          height: 100%;
          @include breakpoint(large) {
            padding-left: .75rem;
            padding-top: .75rem;
            padding-bottom: .75rem;
          }
        }
      }
    }

    .body-header-top {
      margin-top: .25rem;

      .user-avatar {
        margin-left: .5rem;
      }
    }

    .showcase-card-header {

      .header-control {
        // background-color: khaki;
        width: 4.5rem;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
        align-content: stretch;

        .showcase-dropdown {

          .dropdown-button {
            padding-top: .95rem;
          }
        }
      }
    }

    .showcase-description {
      padding-left: 1rem;
      padding-right: 0;

      .body-header-side {
        margin-top: .5rem;

        .showcase-card-header {
          margin-right: 0;

          .header-control {

            .showcase-dropdown {

              .dropdown-button {
                padding-top: .7rem;
              }
            }
          }
        }

        .user-avatar {
          margin-left: 0;
        }
      }

      .body-content {
        text-align: justify;
        margin-right: 2rem;
        padding-top: 1rem;

        .like-container {
          height: 4rem;
          width: initial;
          overflow: hidden;
          padding-bottom: 1rem;

          @include breakpoint(large) {
            width: 2rem;
          }
        }
      }
    }
  }
}
