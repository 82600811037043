@import 'variables';
@import 'mixins';

@keyframes pop {

  50% {
    transform: scale(1.3);
  }
}

.like-container {
  margin-top: .5rem;
  width: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  text-align: center;

  .like-button {
    padding: 0;
    margin: 0;
    cursor: pointer;

    &:focus, &:active, &:target {
      outline: none;
      animation: pop .3s linear 1;
    }

    svg {
      padding: 0;
      margin: 0;
      height: 22px;
      width: 22px;
      fill: $medium-gray;
    }

    &.active svg {
      fill: $ruler-red;
    }
  }

  .like-counter {
    padding: 0;
    display: block;
    color: $black;
    font-size: rem-calc(13);
    height: 1rem;
  }
}
