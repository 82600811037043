// Map icon names to font unicode characters
$icons: (
  arrow-left-circle: '\EA01',
  arrow-right-circle: '\EA02',
  arrow-right: '\EA03',
  arrow-up: '\EA04',
  article: '\EA05',
  audio: '\EA06',
  books-stack: '\EA07',
  calendar: '\EA08',
  check-mark: '\EA09',
  check: '\EA0A',
  copy: '\EA0B',
  courses: '\EA0C',
  cross: '\EA0D',
  cycle: '\EA0E',
  dots-menu: '\EA0F',
  down-chevron: '\EA10',
  download: '\EA11',
  edit: '\EA12',
  eye: '\EA13',
  folder: '\EA14',
  gear: '\EA15',
  guide: '\EA16',
  heart: '\EA17',
  index-finger: '\EA18',
  info-bubble: '\EA19',
  info: '\EA1A',
  keyword: '\EA1B',
  left-chevron: '\EA1C',
  less: '\EA1D',
  lesson-plan: '\EA1E',
  lesson: '\EA1F',
  library: '\EA20',
  light-bulb: '\EA21',
  more: '\EA22',
  mute-button: '\EA23',
  new-file: '\EA24',
  open-book: '\EA25',
  pause-button-minimal: '\EA26',
  pause-button: '\EA27',
  pdf: '\EA28',
  pin: '\EA29',
  play-button-minimal: '\EA2A',
  play-button: '\EA2B',
  poster: '\EA2C',
  printer: '\EA2D',
  question-circle-regular: '\EA2E',
  remove: '\EA2F',
  right-chevron: '\EA30',
  ruler: '\EA31',
  search: '\EA32',
  share: '\EA33',
  thinking: '\EA34',
  timer-button: '\EA35',
  tools: '\EA36',
  upload: '\EA37',
  video: '\EA38',
  volume-button: '\EA39',
  window-restore-regular: '\EA3A',
);
