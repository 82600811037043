@import 'variables';

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 100px;
}

.circle-bg {
  fill: none;
  stroke: $bg-gray;
  stroke-width: 2;
}

.circle {
  fill: none;
  stroke-width: 2;
  stroke-linecap: square;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {

  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.orange .circle {
  stroke: $success-color;
}

.percentage {
  fill: $black;
  font-size: .55em;
  font-weight: 600;
  text-anchor: middle;
}
