.position-relative {
  position: relative;
}

:focus {
  outline: auto;
}

[data-whatintent='mouse'] *:focus {
  outline: none;
}

.hidden {
  display: none !important;
}

html {
  height: 100%;
}

.content-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#header-wrapper {
  z-index: 11;
}

.top-info-banner, .after-login-popup {
  text-align: center;
  background-color: #024985;
  color: #ffffff;
  padding: .75rem;

  a {
    color: #c8f2fd;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

#content {
  flex: 1 0 auto;
}

.orbit {

  .orbit-wrapper {

    .orbit-slide {
      position: relative;

      &:not(.is-active) {
        display: none;
      }
    }
  }
}

body.is-reveal-open {
  overflow: hidden;
}

.header {
  background: $white;
  border-bottom: 1px solid $light-gray;
  width: 100%;
}

.footer {
  background-color: $white;
  border-top: 1px solid $light-gray;
  padding-top: 1.75rem;
}

.menu .logo {
  padding-right: 2rem;

  a {
    padding: 0;
    width: 180px;
  }
}

.article-header {
  margin: 3rem 0;

  .charter-home & {
    margin-top: 5.75rem;
    margin-bottom: 4rem;
    padding: 0;
    background: none;
  }
}

.article-header, .single-post-header {
  padding: 5rem 0;
  margin-bottom: 3rem;
  background: $bg-gray;

  > .grid-container {
    position: relative;
  }

  .page-title {
    margin: 0;
    line-height: 1.2;
  }

  .button.add-to-favorite {
    position: absolute;
  }
}

.single-post-header {
  margin-bottom: 0;
  padding-bottom: 1.5rem;
  background: $white;

  .page-title {
    font-size: rem-calc(48);

    span {
      display: block;
      font-size: 1rem;
      font-weight: 500;
      color: $dark-gray;
      text-transform: uppercase;
      letter-spacing: .0875em;
    }
  }
}

.wp-caption {
  max-width: 100%;
  background: #eee;
  padding: 5px; // Images inside wp-caption

  img {
    max-width: 100%;
    margin-bottom: 0;
    width: 100%;
  }

  p.wp-caption-text {
    font-size: .85em;
    margin: 4px 0 7px;
    text-align: center;
  }
}

.lead {
  font-weight: 300;
}

.content-not-found {
  padding-top: 6rem;
}
// Add Foundation styling to password protect button

.post-password-form input[type='submit'] {
  @include button;
}

/*********************
VISUAL EDITOR STYLES
*********************/

// Makes content look better in visual editor

body#tinymce {
  margin: 20px;
}

/*********************
PLUGIN STYLES
*********************/

// Makes Gravity Forms play nice with Foundation Form styles

.gform_body ul {
  list-style: none outside none;
  margin: 0;
}

.fsp-modal .fst-sidebar .fst-sidebar__option[title='Circle'] {
  display: none !important;
}

.tags {
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;

  h2 {
    font-size: rem-calc(14);
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }

  a {
    display: inline-block;
    padding: .5rem 1rem;
    color: $blue;
    border: 1px solid $blue;
    border-radius: $global-radius;
    white-space: nowrap;
    margin-right: 10px;
    margin-bottom: 10px;

    &:hover {
      border-color: $dark-gray;
      color: $dark-gray;
    }
  }
}

.post-not-found.callout {
  width: 100%;
  text-align: center;
  border: 0;
  font-size: 1.25rem;
  font-weight: 300;
  margin-top: 100px;
}

.modal-footer {
  margin-top: 1.5rem;
}

.modal-header {

  h2 {
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
}

.modal-container {

  label {
    font-weight: 600;

    .option {
      text-transform: uppercase;
      font-size: .6em;
      color: $dark-gray;
      padding-left: 10px;
    }
  }

  fieldset {

    legend {
      font-weight: 600;
    }

    label {
      font-weight: 400;
    }
  }
}

.copyright {
  font-size: rem-calc(14);
  @include breakpoint(medium down) {
    margin-bottom: 1rem;
  }

  small {
    font-weight: 300;
    font-size: inherit;
  }
}

.footer-nav {

  .menu {

    li a {
      font-size: rem-calc(14);
      color: $black;
    }
  }
}

.border-bottom {
  border-bottom: 1px solid $light-gray;
}

.margin-bottom {
  margin-bottom: 1.5rem;
}

.text-underline {
  text-decoration: underline;
}

.plyr--audio {
  border: 1px solid $light-gray;
}

.iframe-placeholder {
  margin: 20px;
}

.break-all {
  word-break: break-all;
}

.short-url {

  &.callout {
    margin-top: 0;

    p {
      margin-bottom: 1rem;
      font-size: 1.5rem;

      &:first-child {
        font-weight: 500;
      }
    }
  }

  span {
    background: $bg-gray;
    padding: 5px;
    font-size: 1.25rem;
    font-weight: 300;
  }

  span + p {
    margin: 1rem 0;
  }

  button {
    cursor: pointer;
    position: relative;
    top: 2px;
  }
}

// Resets p styles in headers. The html content from cms comes wrapped in a p tag.

h1, h2, h3, h4 {

  p {
    margin-bottom: 0;
    line-height: initial;
  }
}
