// For adding font icons to elements using CSS pseudo-elements
// http://jaydenseric.com/blog/fun-with-sass-and-font-icons
@mixin icon($position: before, $icon: false, $styles: true) {
  @if $position == both {
    $position: 'before, &:after';
  }
  // Either a :before or :after pseudo-element, or both, defaulting to :before

  &:#{$position} {
    @if $icon {
      // A particular icon has been specified
      content: '#{map-get($icons, $icon)}';
    }
    @if $styles {
      // Supportive icon styles required
      line-height: 1;
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      font-family: 'rulericons';
    }
    // Include any extra rules supplied for the pseudo-element
    @content;
  }
}
