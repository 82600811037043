.administration {

  .emotion-words thead th, tbody td {
    text-align: center;

    &.not-centered {
      text-align: inherit;
    }

    &.definition {
      width: 25rem;
    }
  }
}
