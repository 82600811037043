@import 'variables';

div.survey-form-callout {
  border: none;

  h2, h4 {

    data p {
      font-size: inherit;
    }
  }

  .survey-form .words {

    .word {

      .word-question {
        margin-bottom: .5rem;
        font-size: 1.5rem;
      }

      h4 {
        text-transform: capitalize;
      }

      .actions-hint {
        margin-bottom: 0;
        font-size: 1rem;
      }

      .actions-border {
        position: relative;

        .actions-list {
          padding-top: .5rem;
          padding-left: 1rem;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: calc(100% - 1.5rem);
            border-left: 1px solid #cfd8e3;
          }

          .action-group-holder {
            padding-left: 1rem;
            position: relative;

            > strong {
              margin-bottom: .5rem;
              display: inline-block;
            }
          }

          &.disabled {
            color: #9eb0c1;
          }
        }
      }
    }
  }
}

.survey-form-actions {

  button.button {
    text-transform: uppercase;
    font-weight: 600;
    padding: 1rem 2rem;

    &:hover {
      color: $white;
    }
  }
}
