@import 'variables';

.toggle-switch.switch.small {

  .switch-paddle {
    width: 6.5rem;
    background: $primary-color;
  }

  &.success {

    .switch-paddle {
      background: $success-color;
    }
  }

  &.plain {

    .switch-paddle {
      background: $light-gray;
    }
  }

  .switch-inactive {
    right: 10%;
  }

  &.switch-is-active {

    .switch-paddle:after {
      left: 5rem;
    }

    .switch-active {
      display: block;
    }

    .switch-inactive {
      display: none;
    }
  }
}
