@import 'variables';
@import 'mixins';

.search-form.showcase-tag-search {
  margin-bottom: 1rem;

  .search-input {
    border: 1px solid $light-gray;
    border-radius: $global-radius;
    padding-top: .5rem;
    padding-bottom: .5rem;
    line-height: 1.5rem;
    font-size: rem-calc(16);
    height: initial;

    &:focus {
      background-color: transparent;
      border-color: $medium-gray;
    }
  }
}
