@import 'variables';

.notification-page {

  h2 {
    padding: 1rem 0;
    margin-bottom: 1rem;
    border-bottom: 1px solid $light-gray;
  }

  .list-item {
    cursor: pointer;
    padding: .5rem;
    border-top: 1px solid $bg-gray;
    box-sizing: content-box;
    transition: all .3s ease;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;

    &.new {
      background-color: #edf2fa;
    }

    &:hover {
      box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
      background-image: linear-gradient(rgba(29, 33, 41, .04), rgba(29, 33, 41, .04));
    }

    p {
      margin: 0;
      padding: 0;
      line-height: inherit;
    }

    i {
      font-size: .65rem;
      margin-right: .25rem;
    }

    .ruler-loading {
      height: 36px;
      width: 36px;
    }

    .notification {

      &-image {
        margin-top: .25rem;
        margin-left: .25rem;
        margin-right: .5rem;
      }

      &-body {
        margin-top: .6rem;
      }
    }
  }
}
