.reg-form {

  .grid-padding-x {
    margin-left: -.25rem;
    margin-right: -.25rem;

    .cell {
      padding-left: .25rem;
      padding-right: .25rem;
    }
  }
}
