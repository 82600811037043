@import 'variables';

.dropdown-pane {
  box-shadow: 0 4px 8px 0 rgba($black, .1), 0 3px 10px 0 rgba($black, .09);

  .add-charter-menu {

    li {
      width: 50%;

      &:hover {
        background: $bg-gray;
      }

      a {
        padding: .5rem;
        text-align: center;
        display: block;
        color: $black;
      }

      span {
        font-size: rem-calc(14);
        line-height: 1.2;
        display: block;
        margin-top: 5px;
      }
    }

    &.menu.icon-top li a img {
      width: 43px;
      align-self: center;
    }
  }
}
