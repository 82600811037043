@import 'variables';
@import 'mixins';

.card.expanded.card-cm-units {

  .card-title {
    text-transform: none;
    font-size: 1.1rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
