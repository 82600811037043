.card.keyword {
  overflow: hidden;
  position: relative;
  margin-bottom: 2rem;

  .card-front {
    text-align: center;
    padding-bottom: 42px;

    img {
      margin-bottom: 1.5rem;
      padding: 0 2rem;
    }

    .card-section {
      padding: 4rem 1.5rem 1.5rem;
    }
  }

  .card-label {
    color: $success-color;
    text-transform: uppercase;
    font-size: rem-calc(14);
    font-weight: 600;
    letter-spacing: .05em;
    line-height: 1;
    position: absolute;
    left: 1rem;
    top: 1rem;

    i {
      font-size: rem-calc(26);

      &:before {
        vertical-align: middle;
      }
    }
  }

  .card-back {
    background: $success-color;
    color: $white;
    position: absolute;
    width: 100%;
    height: 100%;
    top: auto;
    bottom: 40px;
    transform: translateY(100%);
    transition: transform .35s ease;

    .card-section {
      padding: 1.5rem;
    }
  }

  &.open .card-back {
    transform: translateY(0px);
    transition: transform .25s ease;
    top: 0;
    bottom: auto;

    .button.more {

      span:before {
        transform: rotate(45deg);
        transition: transform .25s;
      }

      &.expanded {
        background: darken($success-color, 10%);
      }
    }
  }

  .card-title {
    text-transform: uppercase;
    font-size: rem-calc(20);
    font-weight: 600;
  }

  .button.more {
    background: $success-color;
    color: $white;
    margin: 0;
    border-radius: 0;
    border: 0;
    padding: 0;
    height: 40px;
    line-height: 40px;
    font-size: rem-calc(13);
    font-weight: 600;
    letter-spacing: .05em;
    text-transform: uppercase;

    span {
      position: relative;
      padding-left: 30px;
      top: 0px;
      @include icon(before, more);

      &:before {
        font-size: rem-calc(22);
        height: 24px;
        margin: 0 3px 0 0;
        line-height: 24px;
        vertical-align: middle;
        position: absolute;
        top: -4px;
        left: 0;
      }
    }
  }

  .module-step & {
    margin: 3rem 0;
  }
}
