.hero-section {

  .page-title {
    font-size: rem-calc(42);
    font-weight: 400;
    line-height: 1.15;
    letter-spacing: -1px;
    margin: 0;
    margin-bottom: 1.5rem;
    @include breakpoint(medium down) {
      font-size: rem-calc(30);
      letter-spacing: normal;
    }

    span {
      display: block;
      font-size: .5em;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: .025em;
      color: $dark-gray;
    }

    .vcard {
      border: 0px;
      padding: 0px;
    }
  }

  &.admin-page {

    .page-title {
      margin-bottom: 1rem;
    }
  }

  .taxonomy-description {

    p {
      margin-bottom: 1.5rem;
    }

    a {
      text-decoration: underline;
    }
  }

  > .grid-container {
    height: 100%;
    position: relative;
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  &.home-section-title > .grid-container {
    padding-bottom: 2.75rem;
  }

  &.taxonomy {
    background-color: $cloudburst;
    color: $white;
    padding: 0;

    > .grid-container {
      padding-top: 0;
      padding-bottom: 0;
    }

    .page-title {
      margin: 2rem 0;
    }

    * {
      color: inherit;
    }
  }

  &.courses-home {
    margin-bottom: 3rem;
  }

  &.tools-home {
    margin-bottom: 3rem;
  }

  &.resources-topics, &.resources-tags {
    background-position: 80% center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    color: $white;
    margin-bottom: 3rem;
    min-height: 300px;
    position: relative;

    > .grid-container {
      height: auto;
      @include vertical-center;
      left: 0;
      right: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &.resources-tags {
    padding-top: 4.25rem;
    padding-bottom: 4rem;
    margin-bottom: 2rem;
  }
}

.page-subtitle, .tools-section-description {
  font-size: rem-calc(20);
  font-weight: 300;
  @include breakpoint(medium down) {
    font-size: rem-calc(18);
  }
}

// Post meta

.byline {
  font-size: rem-calc(18);
  font-weight: 300;
  margin-bottom: 0;
}
