.ln-letters {
  display: flex;
  justify-content: center;

  a {
    color: $cloudburst;
    border: 1px solid $light-gray;
    border-right: none;
    display: block;
    font-size: .9em;
    padding: 3px 9px;
    text-decoration: none;
  }

  .ln-last {
    border-right: 1px solid $light-gray;
  }

  a:hover, .ln-selected {

    &:not(.ln-disabled) {
      background-color: $bg-gray;
      color: $cloudburst;
    }
  }

  .ln-disabled {
    color: $light-gray;
    cursor: default;
  }
}

.ln-letter-count {
  color: $blue;
  font-size: .8em;
  left: 0;
  line-height: 1.35;
  position: absolute;
  text-align: center;
  top: 0;
  width: 20px;
}
