@import 'variables';
@import 'mixins';

.showcase-container {

  .showcase-content {
    width: 100%;

    .file-container {
      text-align: center;

      .button {
        text-transform: uppercase;
        padding: 1rem 2rem;
      }

      .image-preview-container {
        display: flex;
        align-items: center;
        padding: 2rem;

        .image-preview {
          width: 100%;
          height: 50vh;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }

      .video-preview-container {
        margin-bottom: 1rem;
      }
    }

    textarea {
      resize: vertical;
      height: auto;
      font-size: rem-calc(18);
      max-height: 10rem;
    }

    .showcase-controls {
      text-align: center;

      > button {
        margin: .5rem;
      }
    }
  }
}

.reveal-overlay {

  .reveal.showcase-details.big {
    @include breakpoint(small) {
      min-height: auto;
      height: auto;
      max-height: auto;
    }
    @include breakpoint(large) {
      width: 100vw;
    }
  }
}

.hero-section {

  .new-showcase-button-container {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }

  &.with-tabs {

    .new-showcase-button-container {
      bottom: .5rem;
    }
  }
}
