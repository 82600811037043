@import 'variables';
@import 'mixins';

.add-mm-map {

  li {
    width: 49%;
    margin-right: 2%;

    &:hover {
      background: $bg-gray;
    }

    &:last-child {
      margin: 0;
    }

    a {
      border: 1px solid $light-gray;
      padding: .5rem;
      text-align: center;
      display: block;
      color: $black;
    }

    span {
      font-size: rem-calc(14);
      line-height: 1.2;
      display: block;
      margin-top: 5px;
    }
  }

  &.menu.icon-top li a img {
    align-self: center;
  }
}

.button.mm-add-map {
  z-index: 1;
}

.mm-tabs {
  border-bottom: 1px solid #c9d3df;
  margin-bottom: 2rem;
}

.mm-card-list, .mm-group-card-list {

  .cell {
    display: flex;
  }
}

.card.mood-card, .card.mood-group-card {
  border-left-width: 6px;
  //min-height: 215px;
  flex: 1;

  &.future {
    border-left-color: $blue;
  }

  .card-content {
    position: relative;
  }

  .count-number {
    font-size: rem-calc(13);
    line-height: 1;
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: .3rem .5rem .25rem;
    background: $success-color;
    color: $white;
    border-radius: $global-radius;
    letter-spacing: .05em;
  }

  .card-date {
    margin: 0;
    font-size: rem-calc(14);
    color: $dark-gray;
    letter-spacing: .0875em;
  }

  .card-title {
    font-size: rem-calc(24);
    font-weight: 600;
    line-height: 1.2;
    padding-right: 2.5rem;
    word-wrap: break-word;
    position: relative;
    flex: 1 0 auto;

    a {
      color: inherit;
    }
  }

  .close-button {
    z-index: unset; // Offset the foundation z-index
  }

  .icon-info {
    font-size: rem-calc(16);
    position: relative;
    left: 5px;
    top: 1px;
    cursor: pointer;
    color: $medium-gray;
  }

  .mood-meta {

    p:last-child {
      margin: 0;
    }
  }
}

.mm-personal-chart {
  margin: 1rem 0 3rem;
}

.results {

  .card.mood-card {
    margin-bottom: rem-calc(20);

    .card-title {
      font-size: rem-calc(18);
    }
  }
}

[data-tabs-content='mm-filter-tabs'] {

  .filter-menu {
    margin-bottom: 2rem;

    form {

      label {
        display: inline-block;
        padding-right: 10px;
        line-height: 1;
        font-weight: 700;
        @include breakpoint(small only) {
          width: 100%;
          display: block;
          margin-bottom: 10px;
        }
      }

      select {
        margin-bottom: 0;
        width: auto;
      }
    }
  }

  .tabs-panel {
    padding-top: 0;
  }
}

.reveal.mm-modal {
  transition-duration: .15s;
  transition-timing-function: linear;
  box-shadow: 0px 11px 15px -7px rgba($black, .2), 0px 24px 38px 3px rgba($black, .14),
    0px 9px 46px 8px rgba($black, .12);
}

.mm-modal-header {
  position: relative;

  h2 {
    text-transform: uppercase;
    font-size: rem-calc(18);
    font-weight: bold;
  }

  .close-button {
    top: -.55rem;
    right: 0;
  }
}

.mm-map-results-container {
  margin: 4rem auto 2rem;

  .page-title {
    margin-bottom: 0;
    line-height: 1.1;
  }

  header {
    margin-bottom: 2rem;
  }
}

.mm-modal-container {
  margin-bottom: 1.5rem;

  form {
    width: 100%;
  }

  legend, .button.mm-add-group {
    font-size: rem-calc(18);
    font-weight: bold;
  }

  legend {
    margin-bottom: 1rem;
  }

  .mm-fields-container {
    border: 1px solid $light-gray;
    padding: 1rem;
  }

  .mm-create-plot-fields-container {
    margin: .5rem 0;
  }

  .radio {

    label {
      border: 0;
    }
  }

  .button.mm-add-group {
    color: $black;
    border-color: $light-gray;
    border-width: 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
    margin: 0;
    padding-left: 2.5rem;
    width: 100%;
    text-align: left;
    position: relative;
    @include icon(before, more);

    &:before {
      color: $success-color;
      margin-right: 1rem;
      position: absolute;
      top: 50%;
      margin-top: -9px;
      left: 1rem;
    }
  }
}

.mm-modal-footer {

  .button {
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 600;
    font-size: .8125rem;
    padding: 0 1.75rem;
    height: 40px;
    line-height: 40px;

    &.mm-save {
      background-color: $success-color;
    }

    &.mm-cancel {
      color: $dark-gray;
    }
  }
}

.mm-wizard-container {
  padding-top: 3rem;
  @include breakpoint(medium) {
    padding-top: rem-calc(64);
  }

  h1 {
    font-size: rem-calc(26);
    font-weight: bold;
  }
}

.mm-slider-container {
  margin-top: 6rem;

  .slider {
    background: $bg-gray;
    border: 1px solid $light-gray;
    border-radius: 3px;
  }

  .slider-fill {
    background-color: transparent;
  }

  .slider-handle {
    border-radius: 3px;
    background-color: $success-color;
    width: rem-calc(36);
    height: rem-calc(24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);

    &:before, &:after {
      content: '';
      background-color: $white;
      width: rem-calc(1);
      height: rem-calc(14);
      @include absolute-center;
    }

    &:before {
      margin-left: -1px;
    }

    &:after {
      margin-left: 1px;
    }
  }

  .slider-scale {
    list-style: none;
    position: absolute;
    margin: 0;
    padding: 0 rem-calc(18);
    top: -40px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    li {
      position: relative;
      height: 24px;

      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 8px;
        background: $medium-gray;
        bottom: -9px;
      }

      span {
        position: absolute;
        transform: translateX(-50%);
      }
    }
  }

  .scale-labels {
    list-style: none;
    margin: 1.5rem 0 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: rem-calc(14);
    font-weight: 600;
  }

  .mm-wizard-button-container {
    margin-top: 7rem;
  }
}

.button.mm-next, .button.mm-back {
  font-size: rem-calc(14);
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 2rem;
  height: 40px;
  line-height: 1;
}

.button.mm-next {
  background: $success-color;

  &:hover, &:focus {
    background-color: $blue;
  }
}

.button.mm-back {
  color: $success-color;
  border-color: $success-color;
  border-width: 2px;

  &:hover, &:focus {
    color: $blue;
    border-color: $blue;
  }
}

.mm-chart-container, .mm-map-container {
  margin-bottom: 2rem;
}

.mm-map, .mm-chart {
  background: $bg-gray;
  @include responsive-embed(1 by 1);
}

.mm-slider-results {
  margin-bottom: 2rem;
  margin-top: 0;

  > ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  li {
    width: 50%;
    text-align: center;
    position: relative;
    padding: 2rem 0 1rem;

    span {
      font-size: rem-calc(24);
      font-weight: 600;
      display: block;
      line-height: 1;
      margin-top: -3.5rem;
    }

    &:first-child {
      border-right: 1px solid $light-gray;
    }
  }
}

.emotion-dropdown {
  box-shadow: 0px 2px 2px 0px rgba($black, .05), 0px 1px 5px 0px rgba($black, .12);
  font-size: rem-calc(14);

  h2 {
    margin-bottom: 0;
    font-size: rem-calc(18);
    font-weight: 700;
  }

  p:last-child {
    margin: 0;
  }
}

.mm-words-list {

  &-wrapper {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  legend {
    font-size: rem-calc(18);
    font-weight: 600;
    line-height: 1.3;
    margin: 1.5rem 0;
  }

  label {
    font-size: rem-calc(18);
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 1rem;

    i {
      font-size: rem-calc(20);
      color: $medium-gray;
      margin-top: 2px;
      right: 1rem;
      @include vertical-center;
    }
  }

  textarea {
    margin-bottom: 2rem;
  }

  .radio {
    width: 100%;
    @include breakpoint(large) {
      width: 49%;
    }
  }

  .radio input + label {
    padding-right: 2.25rem;
    margin-bottom: .5rem;
    font-size: 1rem;
  }

  .radio input:checked + label {

    i {
      color: $white;
    }
  }
}

.mm-group-time {

  &.callout {

    p {

      &.time {
        font-size: rem-calc(24);

        i {
          position: relative;
          top: 2px;
        }

        .icon-timer-button {
          margin-right: .4rem;
        }
      }
    }

    .button {
      margin-top: 1rem;
      background: $success-color;
    }
  }
}

.mm-filter-heading {
  font-size: rem-calc(32);
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.mm-v2-description {

  h1 {
    line-height: 1.2;
    margin-bottom: 3rem;
  }
}

.schedule-chooser {

  span {
    margin: 0 10px;
  }

  button.button {
    margin: 0;
  }

  .flatpickr-input {
    display: inline-block;
    width: 170px;
    margin: 0;
  }
}

.hero-section {

  .time {

    .icon-timer-button {
      margin-right: .25rem;
    }
  }
}
