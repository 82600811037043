@import 'variables';

.word-cloud {
  white-space: wrap;

  span.tag {
    margin: .25rem;
    font-weight: 600;
    padding: .25rem 1rem;
    display: inline-block;
    vertical-align: middle;
    @include border-radius(2rem);
    color: $white;
    background-color: $pill-orange;

    &:hover {
      cursor: pointer;
      background-color: adjust-color($pill-orange, $lightness: 10%);
      @include shadow($prototype-box-shadow);
    }
  }
}
