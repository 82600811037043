.emotion-word-tooltip {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .05), 0 1px 5px 0 rgba(0, 0, 0, .12);
  position: absolute;
  top: 35px;
  left: 35px;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  border: 1px solid #c9d3df;
  border-radius: 0;
  background-color: #fff;
  font-size: 1rem;
  pointer-events: none;

  p:last-child {
    margin-bottom: 0;
  }
}

.overflow-visible {
  overflow: visible;
}
