@import 'variables';
@import 'mixins';

.showcase-card-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;

  .title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    > div {
      margin: .5rem 0 .5rem .5rem;
      display: inline-block;
      line-height: 1.75rem;
    }

    .user-avatar {
      width: 28px;
      height: 28px;
      overflow: hidden;
      border-radius: 50%;
      line-height: 1rem;
    }
  }

  .showcase-dropdown {

    .dropdown-button {
      color: $medium-gray;
      margin: 0;
      padding-top: .85rem;
      padding-right: .5rem;
    }

    .dropdown-pane {
      right: 0 !important;
      top: 2.5rem !important;
      width: 10rem;
      padding: 0;
      padding: 10px;
      box-shadow: 0 0 10px 0 rgba($black, .15);

      .menu li a, .menu li button {
        cursor: pointer;
        color: $dark-gray;
        padding: 5px 0;
        font-size: rem-calc(12);
        text-transform: uppercase;
        letter-spacing: .05em;
        font-weight: normal;

        &:hover {
          color: $cloudburst;
        }
      }
    }
  }
}

.showcase-card {
  border: 1px solid $light-gray;
  border-radius: 5px;
  margin-bottom: 1rem;

  .card-section {
    margin: 0;
    padding: 0;

    .card-media-content {
      display: flex;
      justify-content: center;
      background-color: lighten($light-gray, 5%);

      > a {
        display: block;
        width: 100%;
        text-align: center;

        .media-content {

          &.image {
            height: 16rem;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }

          &.video {
            max-height: 16rem;
            position: relative;
            color: $light-gray;

            &:hover {
              color: lighten($light-gray, 10%);
            }
          }

          .play-overlay {
            @include icon(after, play-button);
            position: absolute;
            top: 40%;
            left: 45%;
            font-size: 2rem;
          }
        }
      }

      .plyr {
        width: 100%;
      }
    }

    .card-footer {

      .cell {

        &.body-content {
          padding: 1rem;
          padding-right: 0;
          font-size: rem-calc(14);
        }

        &.like-content {
          padding: .75rem;
        }
      }
    }
  }
}

.showcase-segment {

  .segment-header a {
    color: $black;

    h4 {
      margin-top: 2rem;
      margin-bottom: .5rem;
    }
  }
}
