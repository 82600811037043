.reveal-overlay {

  .reveal.big {
    @include breakpoint(small) {
      min-height: auto;
      height: auto;
      max-height: auto;
    }
    @include breakpoint(large) {
      width: 100vw;
    }
  }
}

.after-login-popup-modal {

  .close-button {
    color: $white;
  }
}
