@import 'variables';

.assessment-question-wrapper {

  legend {
    font-size: rem-calc(22);
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 1.5rem;
  }

  .radio, .checkbox {

    input + label {
      font-size: 1rem;
      font-weight: 400;
      padding: 1.125em 1em 1.125em 3.5em;
      margin-bottom: 1rem;
    }

    input:checked + label {
      border-color: $blue;
      background: $blue;
    }

    input:checked + label:before {
      border-color: $blue;
    }
  }

  .radio input:checked + label:after {
    background: $blue;
  }

  .checkbox input:checked + label:after {
    background: $white;
    color: $blue;
  }
}

.assessment-question {
  margin-bottom: 1.5rem;
}

.answer-container {

  .correct-answer, .incorrect-answer {
    text-transform: uppercase;
    font-size: rem-calc(13);
    letter-spacing: .075em;
  }

  .correct-answer {
    color: $success-color;
  }

  .incorrect-answer {
    color: $alert-color;
  }

  .answer-explanation {
    font-weight: 300;
    color: $dark-gray;
    margin-top: .5rem;
  }

  [class^='icon-'], [class*='icon-'] {
    top: 2px;
    position: relative;
    margin-right: 5px;
  }
}
