@keyframes loading {

  50% {
    transform: scale(1.3);
  }
}

.loading-container {
  background-color: rgba($color: $white, $alpha: .8);
  position: relative;
  user-select: none;
  text-align: center;
  height: 30rem;
  width: 100%;

  @keyframes fadeIn {

    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  svg {
    animation: fadeIn .5s ease-in;
    animation-fill-mode: forwards; // retain the value of opacity after the animation ends
    margin-top: 6rem;
    width: 12rem;
    height: 12rem;
  }
}

.ruler-loading {
  height: 100%;
  max-height: 20rem;
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;

  .ldl-scale {
    transform-origin: 50% 50%;
    transform: scale(.6);

    .ldl-layer {

      .ldl-ani {
        transform: scale(.91);
        transform-origin: 50px 50px;

        &.red {
          fill: $ruler-red;
          animation: loading 1s -.2s linear running infinite;
        }

        &.yellow {
          fill: $ruler-yellow;
          animation: loading 1s .2s linear running infinite;
        }

        &.green {
          fill: $ruler-green;
          animation: loading 1s .4s linear running infinite;
        }

        &.blue {
          fill: $ruler-blue;
          animation: loading 1s .6s linear running infinite;
        }
      }
    }
  }
}
