.card {

  &.card-right-image.expanded {
    min-height: 250px;

    @include breakpoint(500 down) {
      flex-direction: column;

      .card-image, .card-content {
        width: 100% !important;
      }

      .card-image {
        order: 1;
        height: 200px;
      }

      .card-content {
        order: 2;
      }
    }

    .card-image {
      background-color: $bg-gray;
      padding: 0;
      width: 40%;

      div {
        display: block;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .card-content {
      width: 60%;
      padding: 0;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .card-title {
      margin-bottom: .5rem;

      a {
        color: inherit;
      }
    }

    .card-section {
      padding: 1.5rem;

      &.meta {
        flex: 0 1 auto;
      }
    }

    .card-date {
      font-size: rem-calc(13);
      margin: 0;
      letter-spacing: .05em;
      text-transform: uppercase;
    }

    .card-meta-time {
      font-size: rem-calc(13);
    }

    .progress {
      margin-bottom: 1rem;
      width: 100%;
    }

    .progress-meter {
      background-color: $success-color;
    }

    .course-score, .course-progress {
      position: relative;
      font-size: rem-calc(13);
      font-weight: 700;
      line-height: 1.2;

      span {
        font-size: rem-calc(24);
        font-weight: 300;
        display: block;
      }
    }

    .course-score {
      border-left: 1px solid $light-gray;
    }
  }

  .corner-ribbon {
    width: 200px;
    background: $success-color;
    position: absolute;
    top: 25px;
    left: -50px;
    text-align: center;
    line-height: 50px;
    font-size: rem-calc(12);
    letter-spacing: .05em;
    text-transform: uppercase;
    color: $white;
    transform: rotate(-45deg);

    &.top-right {
      top: 25px;
      right: -50px;
      left: auto;
      transform: rotate(45deg);
    }

    &.bottom-right {
      bottom: 25px;
      top: auto;
      right: -50px;
      left: auto;
      transform: rotate(-45deg);
    }
  }

  &.expanded {
    flex-direction: row;
    position: relative;

    &:before {
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      font-size: 2rem;
      color: $light-gray;
    }

    > .card-section, > .card-content {
      width: 50%;
      padding: $global-padding;
    }

    .back-card {
      background: $bg-gray;
      padding: 2rem;
    }

    .card-title {
      font-size: rem-calc(24);
      font-weight: 600;
      line-height: 1.1;
      margin-bottom: 1.5rem;

      span {
        color: $dark-gray;
        display: block;
        font-size: .6em;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: .05em;
        margin-bottom: rem-calc(6);
      }
    }

    .card-image {
      padding: 0 3rem;
    }
  }
}

.card-actions {
  position: absolute;
  top: 1rem;
  right: 1rem;

  .button {
    padding: 0;
    width: 18px;
    height: 18px;
    font-size: rem-calc(17);
  }

  .dropdown-pane {
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba($black, .15);

    .menu li a, .menu li button {
      cursor: pointer;
      color: $dark-gray;
      padding: 5px 0;
      font-size: rem-calc(12);
      text-transform: uppercase;
      letter-spacing: .05em;

      &:hover {
        color: $cloudburst;
      }
    }
  }
}

.district-card, .school-card, .journal-card {

  &-list {

    .cell {
      display: flex;
    }
  }

  .card-image {
    background: $bg-gray;
    border-bottom: 1px solid $light-gray;
    padding: 1rem;
    height: 8rem;
    display: flex;
    align-items: center;

    img, svg {
      height: 100%;
    }
  }

  .card-title {
    font-size: rem-calc(24);
    font-weight: 500;
    line-height: 1.2;
    padding-right: 2.5rem;
    word-wrap: break-word;
    flex: 1 0 auto;

    a {
      color: inherit;
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    p {
      font-weight: 300;
    }
  }

  .actions {
    flex: 0 1 auto;

    .button {
      margin-bottom: 0;
      padding: 0;
      font-size: rem-calc(12);
      font-weight: 600;
      text-transform: uppercase;

      &:first-child {
        margin-right: 1.5rem;
      }
    }
  }
}
