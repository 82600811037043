.breadcrumb-container-single {
  background: $cloudburst;
  padding: .7rem 0;
  color: $white;

  .back-link {
    font-size: rem-calc(14);
    color: inherit;

    span {
      text-transform: uppercase;
      letter-spacing: .05em;
    }

    i {
      font-size: 1.25rem;
      position: relative;
      vertical-align: middle;
      top: 2px;
    }
  }

  p {
    margin: 0 0 .35rem;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1;
  }

  a {
    color: $white;
  }
}
