.callout {

  p {
    margin: 0;
    font-size: rem-calc(16);
  }

  .questions + div & {
    margin-right: .9375rem;
    margin-left: .9375rem;
  }

  &.message {
    padding-right: 3rem;
  }
}

.dialog {
  transition-duration: .15s !important;
  border-left: 5px solid $medium-gray;
  padding: 1.5rem;

  &.message {
    color: $blue;
    position: relative;
    padding-right: 100px;
    @include icon(after, index-finger);
    border-color: $blue;

    &:after {
      font-size: rem-calc(64);
      color: $blue;
      position: absolute;
      right: 2.5rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .activity-alert-header {
    font-size: rem-calc(18);
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    margin-top: -5px;
  }

  p {
    margin: 1rem 0;
  }

  a {
    text-decoration: underline;
  }

  .button {
    padding: .75rem 1.5rem;
    text-transform: uppercase;
    font-size: rem-calc(12);
    font-weight: 600;
    letter-spacing: .065em;
    margin-bottom: 0;
    margin-right: 5px;
    margin-top: .5rem;

    &.hollow {
      padding: calc(.75rem - 1px) 1.5rem;
    }
  }
}

.alert-header {
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
