@import 'variables';
@import 'mixins';

.survey-item {
  margin: .5rem 1rem;
  position: relative;
  @include icon(after, down-chevron);
  transition: all .25s ease;
  border: 1px solid $light-gray;
  border-radius: 3px;

  &:after {
    font-size: rem-calc(28);
    position: absolute;
    cursor: pointer;
    right: 1rem;
    top: 1rem;
  }

  &:hover {
    border-color: $medium-gray;
  }

  &.active {
    border-color: $dark-gray;

    &:after {
      transform: rotate(180deg);
    }
  }

  .title {
    cursor: pointer;
    padding-right: 3rem;
  }
}
