.user-profile-edit {

  .upload-image {
    font-weight: normal;
  }

  .user-avatar {
    border: 1px solid $medium-gray;
    border-radius: 50%;
    width: 128px;
    height: 128px;
    display: block;
    margin: 0 auto 10px;
    overflow: hidden;
  }

  legend {
    font-weight: 700;
  }

  label {
    font-weight: 600;
  }

  .select2-container {
    margin-bottom: 1rem;
  }

  .hero-section {

    &:not(.with-tabs) {
      margin-bottom: 3rem;
    }

    &.with-tabs {

      .grid-container {
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
      }
    }
  }

  .grid-container {

    &.with-tabs {
      margin-top: 0;
    }
  }
}
