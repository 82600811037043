@import 'variables';
@import 'mixins';

.main-auth-container {
  max-width: 100%;
  @media screen and #{breakpoint(medium)} and #{breakpoint(1470 down)} {

    & > .grid-x > .cell {
      width: 70%;
    }
  }

  @include breakpoint(large) {
    margin-left: 460px;
  }

  .auth-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-bottom: 1.5rem;
  }

  .auth-main {
    flex: 1 0 auto;

    a {
      text-decoration: underline;
    }
  }

  .logo {
    max-width: 170px;
    padding-top: rem-calc(100);
    margin-bottom: 3rem;
  }

  .app-auth-title {
    font-size: rem-calc(32);

    p {
      // <p> nested in a <h1>
      color: inherit;
      font-size: inherit;
    }
  }

  .copyright {
    color: $medium-gray;
    margin-bottom: 1rem;
  }

  .footer-nav .menu li a {
    color: $medium-gray;
  }

  .password-wrapper {
    position: relative;
  }

  .show-pass {
    font-size: rem-calc(24);
    position: absolute;
    right: .75rem;
    top: 2.1rem;
    color: $light-gray;
    cursor: pointer;

    &.active {
      color: $black;
    }
  }

  #password {
    padding-right: rem-calc(30);
  }

  p {
    font-size: rem-calc(14);
    color: $medium-gray;
  }

  label {
    font-size: rem-calc(14);
    font-weight: 600;
  }

  button[type='submit'] {
    background-color: $success-color;
    text-transform: uppercase;
    font-size: rem-calc(13);
    margin-bottom: 2rem;
  }
}
