@import 'variables';

.rulerize-tabs {
  border-bottom: 1px solid $light-gray;
}

.task-list {

  > li {
    border: 1px solid $light-gray;
    border-left-width: 5px;
    border-radius: $global-radius;
    padding: 1rem 1rem 1.25rem;
    margin-bottom: 1.5rem;
    position: relative;
  }

  &.current {

    > li {
      border-left-color: $success-color;

      &.completed {
        border-left-color: $light-gray;

        .task-date {
          color: $medium-gray;

          &.expired {
            color: $alert-color;
          }
        }
      }
    }
  }

  .button {
    margin: 0;
  }
}

.task-title {
  font-size: rem-calc(21);
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: .25rem;
}

.task-date {
  font-size: rem-calc(13);
  text-transform: uppercase;
  color: $success-color;
  letter-spacing: .05em;
  margin: 0 0 .5rem;

  &-expire {
    color: $dark-gray;
    position: relative;
    padding-left: .75rem;
    margin-left: .5rem;

    &:before {
      content: '';
      width: 1px;
      height: 90%;
      position: absolute;
      left: 0;
      top: 0;
      background: $dark-gray;
    }
  }
}

.task-stat {
  display: block;
  color: $dark-gray;
  font-size: rem-calc(13);
  font-weight: 400;
  margin-bottom: .5rem;
  text-transform: uppercase;
  letter-spacing: .05em;
}

.rulerize-category {
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 2rem;
  letter-spacing: .05em;
}

.close-button {
  z-index: unset; // unset it from Foundation styles
}
