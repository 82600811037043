@import 'variables';
@import 'mixins';

.approve-suggestions-form {

  .suggestions-row {
    border: 1px solid $light-gray;
    border-radius: $global-radius;
    margin-bottom: 1rem;
    padding: .25rem;

    .action-label {
      font-size: rem-calc(18);
      font-weight: 600;
      margin-bottom: .5rem;
    }
  }

  .button-like-margins {
    display: inline-block;
    padding: .5rem 1em;
    font-size: 1rem;
    vertical-align: middle;
    font-weight: 600;

    &.suggested-word, &.suggested-action {
      border: 1px solid $light-gray;
      border-radius: $global-radius;
      width: 100%;
      line-height: 1.75rem;
      position: relative;
      margin-bottom: 1rem;
      padding-right: 4rem;

      .toggle-switch {
        position: absolute;
        right: 0;
        top: .5rem;
        display: inline-block;
        margin: 0 .5rem;

        .switch-paddle {
          width: 3rem;
        }

        &.switch-is-active {

          .switch-paddle:after {
            left: 1.5rem;
          }
        }
      }
    }
  }

  .emotion-words, .emotion-suggestions {
    padding-top: .5rem;
    padding-bottom: .5rem;

    > .grid-x > .cell {

      &:not(:last-child) {

        button.button {
          margin-bottom: .2rem;
        }
      }

      &:last-child {

        button.button {
          margin-bottom: 0;
        }
      }
    }
  }

  hr {
    margin-top: 0;
    margin-bottom: 0;
    border-color: lighten($light-gray, 7%);
  }
}

.action-group {

  .submit {
    background-color: $success-color;
  }

  .view-staff-charter-button {
    height: 3rem;
    margin-top: .75rem;
    margin-left: 1rem;
  }
}

.word-suggestions-total {
  font-size: rem-calc(.8rem);
}

button.contributions-form-button.button {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  .text {
    margin-right: 10px;
    text-transform: uppercase;
  }
}
