.onboard-modal {

  .fade-in.mui-enter, .fade-out.mui-leave {
    transition-duration: 100ms;
  }

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &-meter {
      background-color: $success-color;
    }
  }

  .modal-container {
    padding: 3rem 0 1.5rem;
  }

  .logo {
    padding: 0 0 2rem;
  }

  .modal-title {
    font-size: rem-calc(36);
    line-height: 1.2;
    margin-bottom: 2rem;

    span {
      font-size: rem-calc(30);
      display: block;
    }

    & + data p {
      margin-bottom: 1rem;
    }
  }

  .button {
    margin-bottom: 0;
  }

  .orbit-container {
    height: 100% !important;
  }

  .orbit-bullets {
    margin: -.75rem 0 0;
  }

  .orbit-bullets button {
    width: .75rem;
    height: .75rem;
  }

  [data-slide='0'] .modal-container {
    text-align: center;
  }

  .button.next-step {
    display: block;
    margin: 2rem auto 0;
  }

  fieldset {
    margin-bottom: 1.5rem;

    .grid-padding-x {
      margin-right: -.25rem;
      margin-left: -.25rem;

      .cell {
        padding-left: .25rem;
        padding-right: .25rem;
        margin-bottom: .5rem;
      }
    }
  }
}

.onboard-modal, .user-profile-edit {

  .select2-container {
    width: 100% !important;
    margin-bottom: 1.5rem;
  }

  .select2-container--default .select2-selection--multiple {
    border: 1px solid $light-gray;
    border-radius: $global-radius;
  }

  .select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid $medium-gray;
  }

  .select2-container .select2-selection--multiple .select2-selection__rendered {
    vertical-align: top;
  }

  .select2-container .select2-search--inline .select2-search__field {
    margin: 0;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    padding: 2px 5px 2px 20px;
    border-radius: $global-radius;
    border-color: $light-gray;
    background: $bg-gray;
    position: relative;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    font-size: rem-calc(20);
    color: $dark-gray;
    position: absolute;
    left: 5px;
    top: -1px;
  }
}
