@import 'variables';

.resources-topic-info {

  .topic-title {
    line-height: 1.1;
  }

  .tags {
    margin: 0;
    padding-top: 1rem;

    a {
      color: $white;
      border-color: $white;
      border-width: 2px;
    }
  }
}
