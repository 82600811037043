@import 'foundation';

.card-cm-units.card {
  position: relative;

  &-title {
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 800;
  }

  &-action {
    margin-top: 2rem;
    text-align: center;

    .button {
      margin-bottom: 0;
    }
  }
}

.cm-category-description {
  font-size: 1.25rem;
  font-weight: 300;
}

.school-category .description p {
  font-size: rem-calc(20);
  font-weight: 300;
  @include breakpoint(medium down) {
    font-size: rem-calc(18);
  }
}
