.mm-chart-container {
  position: relative;
  margin-bottom: 4rem;
  margin-left: 4rem;
}

.mm-chart-container .mm-slider-container-horizontal {
  margin-top: 0px;
  position: absolute;
  width: 100%;
  padding-left: 50px;
  padding-right: 5px;
}

.mm-chart-container .mm-slider-container-vertical {
  margin-top: -5px;
  position: absolute;
  height: 100%;
  left: 0;
  margin-left: -3rem;
  padding-bottom: 5px;
}

.mm-slider-container-vertical > .slider.vertical {
  /* position the slider next to the grid */
  height: calc(100% - 40px);
  margin-top: 5px;
}

.slider-handle-energy {
  background-color: #33a650;
  width: 20px;
  height: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  position: absolute;
  top: 50%;
  margin-left: -.5rem;
  transform: rotateZ(90deg);
  z-index: 1;
  display: inline-block;
  transition: all .2s ease-in-out;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.slider-handle-energy:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #33a650;
  position: absolute;
  transform: translateY(-100%);
  background-color: transparent;
}

.slider-handle-pleasantness {
  background-color: #33a650;
  width: 20px;
  height: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block;
  transition: all .2s ease-in-out;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.slider-handle-pleasantness:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #33a650;
  position: absolute;
  transform: translateY(-100%);
  background-color: transparent;
}

.hovered canvas {
  cursor: pointer;
}
