@import 'variables';
@import 'mixins';

.card.module-card {

  .card-section {
    position: relative;
    padding: 1.5rem;

    &.meta {
      flex: 0 1 auto;
    }

    .progress {
      width: 75%;
      position: relative;
    }

    .progress-meter {
      background-color: $success-color;
    }

    .progress-meter-text {
      left: 100%;
      transform: translate(0%, -50%);
      padding-left: 10px;
      color: $dark-gray;
      font-size: rem-calc(14);
      font-weight: 600;
    }

    .button.module-button {
      padding: .5rem 1.5rem;
      text-transform: uppercase;
      border-width: 2px;
      font-size: rem-calc(12);
      font-weight: 600;
      margin-top: 1.5rem;

      &.assessment-button {
        background: $success-color;
        color: $white;
      }
    }
  }

  .card-date {
    margin: 0;
    font-size: rem-calc(14);
    color: $dark-gray;
    letter-spacing: .0875em;
  }

  .card-title {
    padding-right: 1.5rem;
    word-wrap: break-word;
    flex: 1 0 auto;

    a {
      color: inherit;
    }
  }

  .card-author {
    font-size: rem-calc(12);
    text-transform: uppercase;
    letter-spacing: .05em;
    margin-bottom: .5rem;
    color: $dark-gray;
  }

  .card-details {
    font-size: rem-calc(13);
    color: $dark-gray;

    span {
      color: $cloudburst;
      letter-spacing: .075em;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  &.expanded {
    min-height: 236px;
    display: flex;
    flex-direction: row;

    &.assessment {
      background: $bg-gray;
      min-height: auto;

      .card-content {
        width: 100%;
      }

      .card-section {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @include breakpoint(small only) {
          justify-content: center;
          flex-wrap: wrap;
        }

        .assessment-title {
          width: 60%;
          @include breakpoint(small only) {
            margin-top: 1rem;
            text-align: center;
            width: 100%;
            order: 2;

            .card-title {
              padding-right: 0;
            }
          }
        }

        .assessment-chart {
          margin-top: 0;
          @include breakpoint(small only) {
            order: 1;
            width: 100%;
          }

          .circle-bg {
            stroke: $white;
          }

          .circle {
            stroke: 2.8;
          }
        }
      }
    }

    .card-title, .card-meta-time{
      margin-bottom: .25rem;
    }

    .card-content, .card-image {
      display: flex;
      flex-direction: column;
    }

    .card-content {
      width: 65%;
      padding: 0;
    }

    .card-image {
      width: 35%;
      background: $bg-gray;
      border-left: 1px solid $light-gray;
      padding: 0;

      div {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    @include breakpoint(medium down) {
      flex-direction: column;

      .card-image, .card-content {
        width: 100%;
      }

      .card-image {
        min-height: 150px;
        order: 1;
        border: 0;
      }

      .card-content {
        order: 2;
      }
    }
  }

  .expanded.no-duration .card-image div {
    background-size: auto 100%;
    background-position: top right;
  }
}
