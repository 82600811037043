.radio, .checkbox {
  position: relative;
}

.radio input, .checkbox input {
  position: absolute;
  top: 50%;
  left: 0;
  visibility: hidden;
  height: 24px;
  width: 24px;
}

.checkbox input {
  top: 0;
}

.radio input + label, .checkbox input + label {
  background: $white;
  border: 1px solid $light-gray;
  border-radius: $global-radius;
  margin: 0 0 .5rem;
  padding: 1.125rem 1rem 1.125rem 3.5rem;
  position: relative;
  display: block;
  line-height: 1;
  font-size: rem-calc(18);
  font-weight: 600;
}

.radio input + label {

  &:before{
    content: '';
    position: absolute;
    top: 50%;
    left: 1rem;
    margin-top: -12px;
    display: inline-block;
    height: 24px;
    width: 24px;
    border: 1px solid $light-gray;
    border-radius: 50%;
  }
}

.radio input:checked + label{
  background: $success-color;
  color: $white;
  border-color: darken($success-color, 10%);

  &:before{
    background: $white;
    border-color: darken($success-color, 10%);
  }

  &:after {
    content: '';
    background: $success-color;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    margin-left: 6px;
    left: 1rem;
    z-index: 2;
  }
}

.radio.small input + label{
  padding: 0 0 0 1.5rem;
  margin-bottom: rem-calc(14);
  font-size: rem-calc(14);
  font-weight: 400;
  border: 0;

  &:before {
    left: 0;
    margin-top: -9px;
    height: 16px;
    width: 16px;
  }
}

.radio.small input:checked + label {
  background: transparent;
  color: $success-color;

  &:after {
    width: 8px;
    height: 8px;
    margin-top: -5px;
    margin-left: 4px;
    left: 0;
  }
}

.radio.clear input + label {
  padding: 1rem;

  &:before {
    border: 0;
  }
}

.radio.clear input:checked + label {

  &:before, &:after {
    border: 0;
    background: transparent;
    z-index: -1;
  }
}

.checkbox input + label {
  font-size: rem-calc(18);
  line-height: 1.5;
  padding-left: 3.5rem;
  margin: 0 0 1rem;

  &:before {
    background: $white;
    content: '';
    position: absolute;
    top: 50%;
    left: 1rem;
    display: inline-block;
    height: 22px;
    width: 22px;
    margin-top: -11px;
    border: 1px solid $light-gray;
    border-radius: $global-radius;
  }
}

.checkbox input:checked + label{
  background: $success-color;
  color: $white;
  border-color: darken($success-color, 10%);
  @include icon(after, check);

  &:before{
    background: $white;
    border-color: darken($success-color, 10%);
  }

  &:after {
    font-size: 13px;
    color: $success-color;
    position: absolute;
    left: 1.2875rem;
    top: 50%;
    margin-top: -6px;
    z-index: 2;
  }
}

.checkbox.small input + label {
  font-size: rem-calc(14);
  font-weight: normal;
  border: 0;
  padding: 0 0 0 1.5rem;
  background: transparent;

  .rulerize-form & {
    font-size: 1rem;
  }

  &:before {
    height: 16px;
    width: 16px;
    left: 0;
    top: 4px;
    margin-top: 0;
  }
}

.checkbox.small input:checked + label {
  color: $success-color;

  .rulerize-form & {
    color: $black;
  }

  &:after {
    font-size: 11px;
    left: 3px;
    top: 7px;
    margin-top: 0;
  }
}

.check-form {

  label {
    font-size: rem-calc(18);
    font-weight: 600;
  }
}

.checkbox-full {
  position: relative;

  input {
    position: absolute;
    top: 50%;
    left: 0;
    visibility: hidden;
  }

  label {
    display: block;
    border: 1px solid $light-gray;
    text-align: center;
    padding: .25rem 0;
    margin: 0;
    border-radius: $global-radius;
  }

  input:checked + label {
    background: $success-color;
    color: $white;
    border-color: darken($success-color, 10);
  }
}

.sort-menu, .filter-menu {

  select {
    color: $dark-gray;
    font-size: rem-calc(13);
    height: 2.25rem;
    max-width: 200px;
    padding: .25rem 1.625rem .25rem .25rem;
  }
}

.datepicker {
  margin-bottom: 1px;
  @include breakpoint(large down) {
    margin-top: 1.5rem;
  }

  legend {
    font-weight: bold;
    padding-right: rem-calc(18);
  }

  label {
    font-size: rem-calc(14);
    font-weight: 600;
  }

  &.grid-padding-x {
    margin-left: -3px;
    margin-right: -3px;
  }

  .cell {
    position: relative;
    padding-left: 3px;
    padding-right: 3px;

    &.auto {
      @include breakpoint(medium only) {
        flex: 0 0 auto;
      }
    }

    input {
      padding: 5px 32px 5px 10px;
      height: 2.5rem;
      width: 99%;
      @include breakpoint(large) {
        margin-bottom: 1.6rem;
      }
    }

    button[type='submit'] {
      margin: 0;
      padding: .7rem 1rem;
      @include breakpoint(medium only) {
        margin-top: 9px;
      }
    }
  }

  .button.open-datepicker {
    padding: 0;
    margin: 0;
    color: $dark-gray;
    position: absolute;
    right: 12px;
    top: 34px;
    font-size: rem-calc(18);
  }
}

.filter-buttons {
  margin-bottom: 1rem;

  .menu {

    > li {
      margin-right: 5px;
    }

    button {
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 3px;
      padding: .75rem;
      border-width: 1px;
    }
  }

  .menu-text {
    padding: .5rem 1rem .5rem 0;
    @include breakpoint(small only) {
      flex: 0 0 100%;
      margin-bottom: .5rem;
    }
  }
}

.search-form {

  .form-fields-wrapper {
    position: relative;

    & + .form-error {
      margin: 0;
      padding: .25rem .5rem;
      background: $alert-color;
      color: $white;
      position: relative;
      top: -1px;
    }
  }

  .search-input {
    border: 0;
    border-bottom: 1px solid $light-gray;
    border-radius: 0;
    height: rem-calc(55);
    padding-left: 1rem;
    padding-right: 3rem;
    margin: 0;

    &:focus {
      background-color: $bg-gray;
      border-color: $medium-gray;

      & + .search-submit {
        color: $medium-gray;
      }
    }
  }

  .search-submit {
    color: $medium-gray;
    @include vertical-center;
    width: rem-calc(27);
    height: rem-calc(21);
    font-size: rem-calc(21);
    line-height: 1;
    right: 1.25rem;
    padding: 0;
    margin: -2px 0 0;
    @include icon(before, search);
  }
}
