.button[type='button'], .button[type='submit'] {
  appearance: none;
}

.button.cta {
  text-transform: uppercase;
  font-size: rem-calc(14);
  font-weight: 600;
  letter-spacing: .05em;
  padding: 1rem 1.5rem;
}

.button.success {
  color: $white;
}

.next-step.button, .next-slide.button {
  background: $success-color;
  text-transform: uppercase;
  font-size: rem-calc(14);
  font-weight: 600;
  padding: .85rem 2.5rem;
  letter-spacing: .025em;

  &:hover, &:focus {
    background-color: $blue;
  }
}

.button.start-module {
  background: $success-color;
  text-transform: uppercase;
  font-size: rem-calc(16);
  font-weight: 600;
  padding: 1rem 2.5rem;
  letter-spacing: .025em;
}

.modal-footer, .modal-header {

  .button {
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 600;
    font-size: .8125rem;
    padding: 0 1.75rem;
    height: 40px;
    line-height: 40px;

    &.upload {
      color: $success-color;
      border-color: $success-color;
      border-width: 2px;
      line-height: 38px;
    }

    &.clear {
      line-height: 38px;
    }
  }
}

.button {

  &.submit {
    text-transform: uppercase;
    font-size: rem-calc(14);
    font-weight: bold;
    padding-left: 2rem;
    padding-right: 2rem;
    letter-spacing: .05em;
    margin-top: .75rem;

    &:hover, &:focus {
      background-color: darken($success-color, 5%);
    }

    &.clear {

      &:hover, &:focus {
        background-color: transparent;
        color: $success-color;
      }
    }
  }

  &.hollow {
    border-width: 2px !important;
    font-weight: 600;

    &:hover {
      border-color: $cloudburst;
      color: $cloudburst;
    }
  }

  &.save, &.edit, &.add, &.complete {
    background-color: $success-color;
    color: $white;
  }

  &.cancel {
    color: $dark-gray;
  }

  &.delete {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &.add, &.complete, &.home, &.more, &.activate, &.delete {
    font-size: rem-calc(13);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .035em;
  }

  &.complete.hollow, &.assign.hollow {
    background: none;
    color: $primary-color;
    padding: .5rem;
    font-size: rem-calc(12);
    text-transform: uppercase;

    &.done {
      color: $success-color;
      border-color: $success-color;
      @include icon(after, check);

      &:after {
        padding-left: 6px;
      }
    }
  }

  &.assign.hollow {
    padding: .5rem;

    &.done {
      border: 2px solid $success-color;
    }
  }

  &.more {
    padding: 0;

    [class^='icon-'], [class*='icon-'] {
      position: relative;
      top: 5px;
      font-size: rem-calc(18);
      line-height: 1;
      display: inline-block;
    }
  }

  &.less {

    [class^='icon-'], [class*='icon-'] {
      transform: rotate(180deg);
      top: 0;
    }
  }
}

.button.fab-button {
  background: $alert-color;
  font-weight: bold;
  font-size: rem-calc(13);
  right: rem-calc(14);
  bottom: -1.5rem;
  margin-top: 1.35rem;
  margin-bottom: 0;
  position: absolute;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0 1px 5px rgba($black, .28);
  transition: all .4s ease;
  z-index: 1;

  .button-text {
    text-transform: uppercase;
    display: block;
    padding-left: 30px;
    font-weight: 600;
    letter-spacing: .05em;
  }

  &:hover {
    transform: scale(1.08);
  }

  &:before, &:after {
    content: '';
    background-color: $white;
    position: absolute;
    transition: transform .25s ease;
  }

  &:after {
    width: 18px;
    height: 2px;
    top: 50%;
    left: 25px;
    margin-top: -1px;
    margin-left: -9px;
  }

  &:before {
    width: 2px;
    height: 18px;
    top: 50%;
    left: 25px;
    margin-top: -9px;
    margin-left: -1px;
  }

  &.hover {

    &:before, &:after {
      transform: rotate(45deg);
    }
  }

  + .dropdown-pane {
    box-shadow: 0 4px 8px 0 rgba($black, .1), 0 3px 10px 0 rgba($black, .09);
  }
}

.feedback-link {
  height: 44px;
  background: $alert-color;
  width: 170px;
  line-height: 44px;
  font-weight: 600;
  color: $white;
  cursor: pointer;
  border-radius: 22px;
  text-align: center;
  font-size: 16px;
  position: fixed;
  right: 20px;
  bottom: 10px;
  z-index: 999;
  transition: all 300ms ease;

  &:hover, &:active, &:focus {
    color: $white;
  }
}

.toggle-button {
  @include icon(after, down-chevron);

  &:after {
    transition: all .25s ease;
    font-size: rem-calc(24);
  }

  &.opened {

    &:after {
      transform: rotateX(180deg);
    }
  }
}

// Red "+ [text-here]" button

.button.new-entity-button {
  background-color: $alert-color;
  box-shadow: 0 1px 5px rgba($black, .28);
  transition: all .4s ease;
  position: relative;

  .button-text {
    text-transform: uppercase;
    display: block;
    padding-left: 30px;
    font-weight: 600;
    letter-spacing: .05em;
  }

  &:before, &:after {
    content: '';
    background-color: $white;
    position: absolute;
    transition: transform .25s ease;
  }

  &:after {
    width: 18px;
    height: 2px;
    top: 50%;
    left: 25px;
    margin-top: -1px;
    margin-left: -9px;
  }

  &:before {
    width: 2px;
    height: 18px;
    top: 50%;
    left: 25px;
    margin-top: -9px;
    margin-left: -1px;
  }

  &:hover, &:focus {
    background-color: darken($alert-color, 10%);
  }

  &.hover {
    background-color: darken($alert-color, 10%);

    &:before, &:after {
      transform: rotate(45deg);
    }
  }
}
