@import 'variables';
@import 'mixins';

.single-resources {

  .resource-toolbar {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    .favorite-button-container {
      position: initial;
    }

    // more specificity for the favorites button in order to overwrite the styles from favorite-button.scss

    .button, .button.add-to-favorites, .button.remove-from-favorites {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 1rem;
      margin: 0 1rem 1rem 0;
      font-size: rem-calc(12);
      font-weight: 600;
      border-width: 2px;
      letter-spacing: .025rem;
      text-transform: uppercase;
      line-height: 1.2;

      .text {
        margin-right: 10px;
        @include breakpoint(small only) {
          position: absolute !important;
          width: 1px;
          height: 1px;
          padding: 0;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          clip-path: inset(50%);
          border: 0;
        }
      }

      .icon {
        font-size: 1.25rem;
      }

      .icon-share {
        font-size: 1.2rem;
      }

      &.share, &.print {
        padding: 0;
      }
    }
  }

  .button.print {
    color: $medium-gray;

    &:hover {
      color: $blue;
    }
  }

  .tags {
    border: 1px solid $light-gray;
    padding: 2rem;

    a {
      background: $white;
      border-color: $light-gray;
    }
  }

  .resource-content {
    padding-bottom: 3rem;

    .accordion {

      ul {
        margin-left: .875rem;
        margin-bottom: 1.5rem;

        li {
          margin-bottom: .75rem;
        }
      }

      .accordion-title {
        background: $bg-gray;
        color: $black;
        font-weight: 600; //font-size: 1.5rem;
        @include icon(after, down-chevron);
        transition: all .25s ease;

        &:after {
          font-size: rem-calc(24);
          position: absolute;
          right: 1rem;
          top: 50%;
          margin-top: -10px;
        }

        h2 {
          font-size: inherit;
          line-height: 1;
          margin: 0;
        }
      }

      .accordion-item {
        border-bottom: 1px solid $white;

        &:last-child {
          border: 0;
        }

        &.is-active {

          .accordion-title {

            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    > p:first-child:first-of-type {
      font-size: rem-calc(24);
      font-weight: 300;
    }

    .plyr--video {
      margin-bottom: 1.5rem;
    }
  }

  .resource-image {

    figcaption {
      color: $black;
      font-weight: 300;
      font-size: rem-calc(14);
      margin: .5rem 0 0;
    }
    margin-bottom: 1.5rem;
  }

  .resource-meta {
    text-transform: uppercase;
    font-size: rem-calc(13);
    font-weight: 600;
    margin-bottom: 1rem;
    letter-spacing: .1em;
  }

  .sidebar-links {
    border: 1px solid $light-gray;
    padding: 1rem;

    .menu .menu-text {
      font-size: rem-calc(16);
      padding: 0;

      h2 {
        font-size: inherit;
        margin: 0 0 .5rem;
        line-height: inherit;
        text-transform: uppercase;
        letter-spacing: .05em;
        border-bottom: 1px solid $light-gray;
        padding-bottom: .75rem;
      }
    }

    .menu {

      a {
        padding: .75rem 0;
        font-size: rem-calc(14);
      }
    }
  }
}

.resource-type {
  display: block;
  text-transform: uppercase;
  letter-spacing: .0875rem;
  font-weight: 500;
  color: $medium-gray;

  &:before {
    font-size: 1.5rem;
    font-weight: 400;
    margin-right: 7px;
    position: relative;
    top: 5px;
  }

  &.article {
    @include icon(before, article);
  }

  &.activity {
    @include icon(before, ruler);
  }

  &.reflection {
    @include icon(before, thinking);
  }

  &.keyword-card, &.glossary {
    @include icon(before, keyword);
  }

  &.video {
    @include icon(before, video);
  }

  &.tipsheet {
    @include icon(before, light-bulb);
  }

  &.lesson-plan {
    @include icon(before, lesson-plan);
  }
}
