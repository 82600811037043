@import 'variables';
@import 'mixins';

.showcase {

  .hero-section {
    background-color: $white;
  }

  #tabs-container.is-stuck .showcase-tabs {

    .tabs {
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto;

      .tabs-title a {

        &:after {
          left: 1rem;
          right: 1rem;
        }
      }
    }
  }
}
