@import 'variables';

ul.autocomplete-list {
  position: absolute;
  overflow-y: auto;
  padding: .5rem;
  border: 1px solid #c9d3df;
  border-radius: 3px;
  margin: 0;
  background: white;
  list-style-type: none;
  right: 0;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .15);
  width: 100%;
  max-height: 400px;
  z-index: 20;

  li {
    margin: 0;

    button {
      padding: .5rem;
      display: block;
      width: 100%;
      text-align: left;

      &:focus, &:hover {
        background: #ebeff3;
        color: $black;
      }

      span {
        background: #ebeff3;
        color: #1159a9;
        display: inline-block;
      }
    }
  }

  &:empty {
    display: none;
  }
}

.autocomplete-input.search-input {
  height: 2.25rem;
  font-size: .8125rem;
  margin-bottom: 0;
}

.search-menu {
  position: relative;

  .close-button.small {
    z-index: unset; // unset it from Foundation styles
  }
}
