@import 'variables';
@import 'mixins';

.cm-lesson-overview {

  strong {
    flex-shrink: 0;
  }

  .objective-container {
    margin-left: 1.25rem;
  }

  .module-objective-list {
    list-style: decimal;
  }

  li {
    align-items: center;
  }

  li .pdficon {
    width: 22px;
    height: 22px;
    margin: 0;
    border: 2px solid #0f4d92;
    border-radius: 50%;
    display: inline-block;
    line-height: 1;
    text-align: center;
  }

  div > p:last-child {
    margin: 0;
  }
}

.lesson-title {
  margin-bottom: 5rem;
  font-size: rem-calc(48);
  font-weight: 600;
  line-height: 1.2;

  span {
    display: block;
    text-transform: uppercase;
    font-size: .5em;
    font-weight: 400;
  }
}

.cm-lesson-sidebar {
  position: absolute;
  width: 28rem;
  left: -28rem;
  max-height: 65vh;
  padding: 0 1rem;
  background: $bg-gray;
  overflow-y: auto;
  transition: .25s ease-in-out left;

  &.cm-lesson-sidebar-open {
    left: 0;
  }

  .units .unit {

    .unit-title {
      font-size: 1rem;
    }

    .unit-lesson-title {
      font-size: .9rem;
    }
  }
}

main.cm-lesson-content {
  position: relative;
  height: 100%;

  @media print {

    a[href]:after {
      content: none !important;
    }
  }

  .lesson-section {
    @media not print {
      min-height: 80vh;
    }

    @media print {

      .button[data-open] {
        // Hide popup buttons
        display: none;
      }

      .grid-x > .large-8 {
        // Override remote CMS content markup
        // large-8 should behave like large-12 for print
        width: 100%;
      }

      .grid-x > .large-4 {
        // large-4 should behave like large-6 for print
        width: 50%;
      }
    }
  }

  .cm-lesson-sections {
    height: 100%;

    p, li {
      font-size: 1.25rem;
    }

    a {
      margin: 1rem 0;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;

    .section-title {
      font-size: rem-calc(42);
      font-weight: 700;
      line-height: 1.2;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
      position: relative;

      @media print {
        font-size: rem-calc(22);
      }
    }

    h2 {
      font-size: 2.5rem;
      font-weight: 600;

      @media print {
        font-size: rem-calc(22);
      }
    }

    .section-footer {
      text-align: center;
      margin-top: 2rem;
    }

    .cm-lesson-overview {
      list-style-type: none;
      margin: 2rem 0 0;
      padding: 0;

      & > li {
        display: flex;
        font-size: 1.25rem;
        padding: 1rem 0;

        @media not print {
          border-bottom: 1px solid $light-gray;
        }

        @media print {
          font-size: rem-calc(18);
          padding: .5rem 0;
        }

        > strong {
          display: block;
          width: 14rem;
        }
      }
    }

    .cm-lesson-section-meta {
      color: $dark-gray;
      display: flex;
      margin-bottom: 2rem;

      .cm-lesson-section-duration {
        padding-right: 1rem;
        @include icon(before, timer-button);

        &:before {
          margin-right: .5rem;
        }
      }

      .cm-lesson-section-type {
        padding-left: 1rem;
        border-left: 1px solid $light-gray;
      }
    }

    .section-text {
      margin-bottom: 1.5rem;
      font-size: 1.25rem;
      font-weight: 300;
    }
  }
}

#lesson-outro {
  min-height: 80vh;
  max-height: 100%;
}

#lesson-outro .grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cm-unit-subbar {
  padding: .5rem 0;
  background: white;

  @media not print {
    border-bottom: 1px solid $light-gray;
  }

  h1 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;

    span {
      text-transform: uppercase;
    }
  }

  .button.sidebar {
    display: flex;
    margin-bottom: 0;
    @include icon(after, down-chevron);

    &:after {
      margin-left: .5rem;
    }

    &.sidebar-open {
      @include icon(after, cross);
    }
  }

  .button.print {
    margin-bottom: 0;
    @include icon(after, printer);

    &:after {
      line-height: 0;
    }
  }
}

.reveal {
  padding: 2.5rem;
  font-size: 1.25rem;
}
