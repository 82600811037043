@import 'variables';
@import 'mixins';

.reveal.video-introduction-modal {
  width: 100%;
}

.nudges-list.accordion {

  .accordion-title {
    color: $black;
    @include icon(after, down-chevron);
    transition: all .25s ease;

    &:after {
      font-size: rem-calc(24);
      position: absolute;
      right: 1rem;
      top: 1.25rem;
    }
  }

  .accordion-item {
    border: 1px solid $light-gray;
    margin-bottom: 1.5rem;

    &.is-active {

      .accordion-title {

        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .accordion-title, .accordion-content {
    padding: 1.5rem;
  }

  .accordion-content {
    padding-bottom: 0;
  }
}

.nudges-group-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: .5rem;

  span {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .05em;
    margin-bottom: .5rem;
  }
}

.nudges-counter {
  font-size: rem-calc(14);
  color: $dark-gray;
  margin-bottom: 1rem;

  &.done {
    color: $success-color;
  }
}

.nudges-desc {
  margin: 0;
  font-size: 1rem;
}

.nudges-task-list {
  list-style-type: none;
  margin: 0;

  .instructions {
    border: none;
  }

  .setup {
    background-color: $bg-gray;

    li {
      background-color: $white;
    }

    .nudge-task-body, .nudge-task-action {
      width: 100%;
    }

    .nudge-task-body {
      margin-bottom: 1rem;
    }
  }

  .nudges-task-list {
    width: 100%;

    .nudge-task-body {
      width: 100%;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  .nudge-task-action {
    padding-top: 1rem;

    .button {
      margin: 0;
    }
  }

  li {
    padding: 1rem;
    margin-bottom: 10px;
    border: 1px solid $light-gray;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    &:last-child {
      margin-bottom: 1.5rem;
    }

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    @include breakpoint(medium down) {
      flex-direction: column;

      > div {
        width: 100% !important;
        text-align: left !important;
      }
    }

    .nudge-task-body {
      width: 70%;

      &:last-child {
        width: 100%;
      }
    }

    .nudge-task-action {
      width: 30%;
      text-align: right;

      .assigned-label {
        color: $success-color;
        margin-right: 1rem;
        margin-left: 1rem;
        display: inline-block;
        font-size: .85rem;

        i.icon {
          margin-right: .25rem;
          vertical-align: middle;
        }
      }

      button.complete {
        border: 2px solid transparent;

        &.done {
          border: 2px solid transparent;
        }

        &:not(.done) {

          &:hover {
            color: $blue;
            border: 2px solid $blue;
          }
        }
      }

      button.assign {

        &:not(.done) {

          &:hover {
            color: $white;
            border-color: lighten($blue, 5%);
            background: lighten($blue, 5%);
          }
        }
      }
    }

    .nudge-task-stats {
      font-size: rem-calc(14);
      color: $dark-gray;
      margin-bottom: .5rem;
    }

    p {

      a {
        font-weight: 600;
        font-size: rem-calc(14);
        border: 2px solid $blue;
        background: $blue;
        color: #fff;
        padding: 2px 6px;
        border-radius: 4px;
        transition: all .25s ease;
        white-space: nowrap;

        &:hover {
          border-color: lighten($blue, 10%);
          background: lighten($blue, 10%);
        }
      }

      &:last-child {
        margin: 0;
      }

      .button {
        margin: 0;
        padding: .5rem 1rem;
      }
    }
  }
}
