@import 'variables';
@import 'mixins';

.admin-links {
  margin-top: 1rem;
}

.admin-link {
  padding: .75rem 3rem .75rem 1rem;
  text-align: left;
  text-transform: uppercase;
  font-size: rem-calc(12);
  font-weight: 600;
  position: relative;
  @include icon(after, gear);

  &:hover {

    &:after {
      color: $blue;
    }
  }

  &:after {
    font-size: rem-calc(20);
    position: absolute;
    right: 1rem;
    top: .5rem;
    color: $blue;
  }
}

.feeling-words-container {
  margin-bottom: 1.5rem;
  font-size: rem-calc(18);
}

.feeling-words-list {

  li {
    height: rem-calc(36);
    line-height: rem-calc(36);
    padding: 0 1.5rem;
    border: 0;
    border-radius: rem-calc(36);
    background-color: $success-color;
    color: $white;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 0;
    position: relative;

    span {
      font-size: 1rem;
      font-weight: 600;
      vertical-align: middle;
      display: inline-block;
      line-height: rem-calc(36);
    }
  }
}

.user-home {
  min-height: 100%;
  padding-bottom: 3rem;
  position: relative;

  .main {

    .callout {
      font-size: rem-calc(18);
      margin: 0 0 rem-calc(30) 0;
      padding: 1.5rem;

      p {
        margin-bottom: 1.5rem;
        font-size: inherit;
      }

      .button {
        border-width: 2px;
        text-transform: uppercase;
        font-size: rem-calc(13);
        font-weight: 600;
        padding: .75rem 2rem;
        letter-spacing: .025em;
      }
    }
  }

  .hero-section {
    margin-bottom: 3rem;

    .media-content.image {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .media-content.image, svg {
      height: 120px;
      width: 120px;
    }
  }

  .page-title, .page-subtitle, .admin-links, .feeling-words-container {
    @include breakpoint(small only) {
      padding-left: 0;
      text-align: center;
    }
  }

  .page-title {
    margin-bottom: .25rem;

    span {
      display: inline;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      text-transform: none;
      letter-spacing: normal;
    }
  }

  .cta-wrapper {
    @include breakpoint(small only) {
      margin-top: 1.5rem;

      .button {
        width: 100%;
      }
    }

    @include breakpoint(medium) {
      text-align: right;
    }
  }
}

.home-section-title {
  font-size: rem-calc(30);
  font-weight: 600;
  margin-bottom: 1rem;
  display: inline-block;
  line-height: 1.2;

  & + .edit-link {
    margin-bottom: 1.5rem;
    display: inline-block;
  }
}

.segment-header {

  a {
    color: $black;

    &:hover {
      color: $blue;
    }

    h4 {
      font-size: rem-calc(22);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      p {
        line-height: inherit;
      }
    }
  }
}

.dashboard-card {
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid $light-gray;

  &.shadow {
    border: none;
    @include shadow($prototype-box-shadow);
  }

  p:last-child {
    margin-bottom: 0;
  }

  .announcement-feed & {
    padding: 1rem;

    a {

      h4 {
        font-size: rem-calc(18);
        margin-bottom: 0;
      }
    }

    span {
      font-size: rem-calc(13);
    }
  }

  &.announcement {
    border: none;
    border-top: .25rem solid orange;
    margin-bottom: 3rem;

    &:empty {
      display: none;
    }

    h1 {
      text-align: left;
      font-size: rem-calc(28);
      margin-bottom: .75rem;
    }

    p {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a.button {
      text-shadow: 0 -1px 1px #cc8b00;
      margin-bottom: 0;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 600;
      padding: .75rem 2rem;
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }

  &.roadmap {
    padding: 0;

    .cell:first-child {
      padding: 1.5rem;
    }

    .cell:last-child {
      background-size: cover;
      background-position: -5.5rem;
      background-repeat: no-repeat;
    }
  }

  .roadmap-segment &, .staff-charter-segment & {

    .button {
      text-transform: uppercase;
      padding: .75rem 2rem;
      font-weight: 600;
    }
  }
}

.quote-card {
  background: $white;
  border: 1px solid $light-gray;
  border-radius: $global-radius;
  overflow: hidden;
  margin-bottom: rem-calc(30);
}

.reward-card {

  &.callout {
    padding: 1.5rem;
  }
}

.feeling-words-list-v2 {

  li {
    display: inline-block;

    &:after {
      content: ',';
    }

    &:last-child {

      &:after {
        content: '';
      }
    }
  }
}
