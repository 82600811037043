@import 'variables';

.survey-form {

  .words.stats {

    .stats-actions {
      border-left: 2px solid $medium-gray;
      padding-left: 1rem;
    }
  }
}
