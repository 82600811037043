@import 'variables';
@import 'mixins';

.questions {
  padding-top: rem-calc(30);

  label {
    font-size: rem-calc(18);
    font-weight: 700;
    color: $cloudburst;
    line-height: 1.25;
    margin-bottom: .5rem;
  }

  input, select, textarea {
    margin-bottom: 2rem;
    color: $cloudburst;
    border: 2px solid $medium-gray;

    &:focus {
      box-shadow: none;
      border-color: $success-color;
      border-width: 2px;
    }
  }

  textarea {
    min-height: 100px;

    & + .counter {
      position: absolute;
      bottom: 10px;
      right: .9375rem;
      text-align: right;
      font-size: rem-calc(14);
    }
  }

  .orbit-container {
    height: 100% !important;
  }

  .orbit-slide {

    &.is-active {

      .q-item {
        display: block;
      }
    }

    .q-item {
      display: none;

      .owl-prev {
        margin-right: 5px;
      }
    }
  }

  .question-intro-text {
    text-align: center;
  }

  .video {
    margin-bottom: 3rem;
  }
}

.post-not-found {
  text-align: center;

  img {
    max-width: 200px;
    margin-bottom: 2rem;
  }
}

.question-form {
  margin: 3rem 0 1rem;
  position: relative;
}

.question-audio-guide {
  margin: 1.5rem 0;

  .question-audio-guide-header {
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  .plyr--audio .plyr__controls {
    border: 2px solid darken($zombie, 25%);
  }
}

.template-select {
  margin-bottom: 3rem;

  legend {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: rem-calc(18);
    font-weight: 600;
    display: block;
    width: 100%;
  }

  label {
    margin-bottom: $global-padding * 2;
  }

  .select-box {
    background-color: #fff;
    background-size: cover;
    transition: all 250ms ease;
    will-change: transition;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    position: relative;
    border: 2px solid $medium-gray;
    border-radius: $global-radius;
  }

  input[type='radio']:checked ~ .select-box {
    box-shadow: 0 0 20px 0 rgba($black, .2);
    border-color: $success-color;
    @include icon(before, check-mark);

    &:before {
      @include absolute-center;
      color: $success-color;
      font-size: rem-calc(32);
    }
  }
}

.instructions {
  text-align: center;
  font-weight: 300;
  color: $dark-gray;
}

body.is-reveal-open {
  height: 100%;
}

.charter-modal.reveal {
  padding: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  height: 100% !important;
  border: 0 !important;
  min-height: initial;
  right: auto;
  margin: 0;
  transition-duration: .125s;
  transition-timing-function: linear;

  .button.next-button, .button.prev-button, .button.finish-button {
    background: $primary-color;

    &:hover, &:focus {
      background: $success-color;
    }
  }

  .button.prev-button {
    padding: 0;
    width: 40px;
    height: 40px;
    font-size: rem-calc(24);
    margin-right: 4px;

    [class^='icon-'] {
      position: relative;
      top: 3px;
    }
  }
}

#add-new-modal {
  background: $white;
}

.charter-button-wrapper {
  text-align: center;

  .button {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: .075em;
    font-weight: 600;
    font-size: rem-calc(13);
    padding: 0 1rem;
    height: 40px;
    line-height: 40px;
  }

  .upload-button {
    position: relative;
    padding-left: 2.75rem;
    @include icon(before, 'arrow-up');

    &:before {
      position: absolute;
      left: 10px;
      font-size: rem-calc(23);
      top: 7px;
    }
  }
}

.charter-modal-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.charter-modal-header {
  height: 40px;
  background: $cloudburst;

  .close-button {
    background: $white;
    float: right;
    position: relative;
    top: 1px;
    margin-top: -1px;
    width: 45px;
    height: 40px;
    border-radius: 0;
  }
}

.charter-modal-footer {
  height: 65px;
  background: $bg-gray;
  border-top: 1px solid $light-gray;
  padding: .75rem 0;
}

.charter-modal-container {
  height: calc(100% - 65px);
  overflow-y: scroll;
}

#edit-new-modal {

  .question-input-container {
    margin-bottom: 1.5rem;
  }
}

.uploaded-charter-image {
  margin-right: .9375rem;
  margin-left: .9375rem;
}
