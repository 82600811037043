@import 'variables';

.checkpoint-transcript-container {
  background: $white;
  border: 1px solid $light-gray;
  margin-top: -1px;
}

.checkpoint-transcript-control {

  button {
    border-radius: 0;
    font-size: rem-calc(11);
    display: block;
    cursor: pointer;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    &.open {
      background: $bg-gray;
      border-bottom: 1px solid $light-gray;
    }
  }
}

.checkpoint-transcript {
  font-size: rem-calc(16);
  height: 0;
  width: 100%;
  overflow: auto;
  padding: 0 1rem;

  *:first-child {
    margin-top: 1rem;
  }
}
