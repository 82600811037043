@import 'variables';
@import 'mixins';

.breadcrumb-container {
  visibility: hidden;
  transition: all .1s linear;
  position: absolute;
  width: 100%;
  z-index: 1;

  @media print {
    display: none;
  }

  .breadcrumb-content {
    background: $cloudburst;
    padding: .7rem 0 1.25rem;
    color: #fff;
  }

  &.is-stuck {
    position: fixed;
    width: 100%;
    visibility: visible;
  }

  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .progress-meter {
    background-color: $success-color;
  }

  a {
    color: inherit;
  }

  .course-link {
    font-size: rem-calc(14);
  }

  p {
    margin: 0;
    font-size: rem-calc(20);
    font-weight: 600;
    line-height: 1;
  }
}
