@import 'variables';
@import 'mixins';

.favorite-button-container {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;

  .button.add-to-favorites, .button.remove-from-favorites {
    background: $white;
    width: 35px;
    height: 35px;
    margin: 0;
    padding: 0;
    line-height: 35px;
    border-width: 2px;
    // transition only on the needed properties
    transition: color .3s ease-in-out, border-color .3s ease-in-out;

    .icon-heart {
      font-size: rem-calc(17);
      position: relative;
      top: 1px;

      &:before {
        display: block;
      }
    }

    .single-resources & {
      position: static;
      width: auto;
      line-height: 1;
      border-radius: 3px;
      background: transparent;
    }
  }

  .favorites-number {
    text-align: center;
    color: $medium-gray;
    cursor: default;
  }
}

.button {

  &.add-to-favorites {

    .single-resources .resource-toolbar & {
      color: $blue;
      border-color: $blue;
    }

    .resource-item & {
      color: $medium-gray;
      border-color: $medium-gray;
    }

    .single-resources .resource-toolbar &, .resource-item & {

      &:hover {
        color: $alert-color;
        border-color: $alert-color;
      }
    }
  }

  &.remove-from-favorites {

    .single-resources .resource-toolbar &, .resource-item & {
      color: $alert-color;
      border-color: $alert-color;
    }

    .single-resources .resource-toolbar & {

      &:hover {
        border-color: $blue;
        color: $blue;

        .icon {
          color: $blue;
        }
      }
    }
  }

  .resource-item & {
    border-radius: 50%;

    &:hover {
      background: white;
    }
  }
}
