@import 'variables';

.users-file-upload {

  .file-label {
    font-weight: 700;
    display: inline-block;
    position: relative;

    input[type='file'] {
      width: .1px;
      height: .1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }

  .form-error {
    margin: 0;
    padding: .5rem;
  }

  .users-grid {
    margin-top: 1rem;

    .rows {
      max-height: 50vh;
      overflow-y: scroll;
      overflow: -moz-scrollbars-vertical;
    }

    .form-error {
      background-color: $bg-gray;
    }

    .list-item {
      margin: 0;
      padding: 0;
      border-bottom: 1px dashed $light-gray;
      box-sizing: border-box;

      &.header {
        background-color: $bg-gray;
        font-weight: 600;
        border-bottom: 2px solid $light-gray;
        padding-right: 16px; // Scrollbar width

        .text-cell {
          padding-top: .5rem;
        }

        .checkbox-cell {

          input {
            margin: 0;
            margin-top: .75rem;
          }
        }
      }

      &.selected {
        background-color: $bg-gray;
      }

      .role-cell {
        padding: .35rem;

        select {
          margin: 0;
          font-size: .85rem;
          height: 2rem;
          line-height: 2rem;
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      .checkbox-cell {
        cursor: pointer;

        input {
          margin: 0;
          margin-top: 1rem;
        }
      }

      .text-cell {
        font-size: .85rem;
        padding-top: .75rem;
        padding-bottom: .35rem;
        word-wrap: break-word;
      }

      > div.cell {
        border-left: 1px dashed $light-gray;

        .form-error {
          padding: 0;
          background-color: inherit;
        }
      }
    }
  }

  .upload-description {

    .rows {
      max-height: inherit;
      overflow-y: auto;
    }

    .list-item.header {
      padding: 0;
    }

    .download-sample {
      padding: 1rem 0 0 1rem;
      font-size: small;
    }
  }
}
