@keyframes confetti {

  0% {
    transform: translateY(40%) rotate(0deg);
  }

  50% {
    transform: translateY(-40%) rotate(180deg);
  }

  100% {
    transform: translateY(40%) rotate(360deg);
  }

  0%, 14%, 17%, 43%, 53%, 71%, 80%, 94%, 100% {
    opacity: 0;
  }

  6%, 15%, 24%, 28%, 48%, 55%, 78%, 82%, 99% {
    opacity: 1;
  }
}
