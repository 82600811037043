@import 'variables';
@import 'mixins';

.topic-category {
  font-size: rem-calc(24);
  font-weight: 600;
  margin-bottom: 1.75rem;
}

.listNav, .ln-letters {
  overflow: hidden;
}

#glossarylist-nav {
  margin-bottom: 1.5rem;
}

.glossarytable {
  margin-left: 0;
  margin-bottom: 1.5rem;
  list-style: none;

  .cell {
    display: flex;
    flex-direction: column;
  }
}

.resource-topic.card {
  background-color: $blue;
  border: 0;
  background-size: initial;
  background-repeat: repeat;
  box-shadow: 0 -1px 3px -2px rgba($black, .3);
  margin-bottom: 2.25rem;
  overflow: visible;

  &:after, &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $bg-gray;
    border: 1px solid $light-gray;
    border-radius: $global-radius;
    transform-origin: center top;
    transition: transform .2s linear;
  }

  &:before {
    background-color: lighten($bg-gray, 8%);
    box-shadow: 0 -1px 3px -2px rgba($black, .1);
    transform: translate(0, -6px) scale(.95);
    z-index: -1;
  }

  &:after {
    background-color: $bg-gray;
    transform: translate(0, -11px) scale(.9);
    z-index: -2;
  }

  &:hover, &:focus {
    box-shadow: none;

    &:before {
      transform: translate(0, -10px) scale(.92);
    }

    &:after {
      transform: translate(0, -18px) scale(.85);
    }
  }

  > a {
    background-color: $blue;
    border-radius: $global-radius;
    overflow: hidden;
  }

  .card-section {
    position: absolute;
    left: 0;
    top: 0;
    bottom: auto;
    width: 100%;
    z-index: 2;
    padding: 1.5rem 4rem 1.5rem 1.5rem;
  }

  .card-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: $white;
  }
}

.resource-topic, .resource-item {
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: ratio-to-percentage(3 by 4);

  &.card {
    margin-bottom: 1.875rem;
    border: 0;
    transition: .1s;

    &:hover, &:focus, &:active {
      box-shadow: 0 0 2px $light-gray;
    }

    > a {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      &:after {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }

    .triangle {
      position: absolute;
      right: -1px;
      bottom: -1px;
      width: 0;
      height: 0;
      border-radius: 3px 0 0;
      border-style: solid;
      border-width: 0 0 50px 50px;
      border-color: transparent transparent $success-color transparent;
      box-shadow: -1px -1px 0 $light-gray, -2px 2px 2px $light-gray;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1px;
        height: 200px;
        background: darken($success-color, 10%);
        transform: translate(-50%, -50%) rotate(45deg);
        box-shadow: 1px 1px 2px darken($success-color, 18%);
      }
    }

    .triangle-text {
      position: absolute;
      width: 100%;
      font-size: 11px;
      text-align: center;
      color: $white;
      top: 34px;
      right: 27px;
      transform: rotate(-45deg);
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .card-title {
      font-size: rem-calc(21);
      font-weight: 600;
      line-height: 1.2;
      padding-bottom: 0;

      a {
        color: inherit;
      }
    }

    .resource-count, .new-label {
      color: $white;
      display: inline-block;
      font-size: rem-calc(13);
      font-weight: 400;
      letter-spacing: .05em;
      text-transform: uppercase;
      margin-top: .35em;
    }

    .new-label {
      background: $success-color;
      border-radius: 2px;
      line-height: 1;
      padding: 5px 7px 3px 6px;
      margin-left: 5px;
    }

    .counter {
      font-size: .675em;
    }
  }
}

.resource-item.card {
  border: 1px solid $light-gray;
  border-left-width: 5px;

  .card-title{
    color: $black;
  }

  .card-section {
    padding: 3.5rem 4rem 1.5rem 1.5rem;
  }

  .card-label {
    position: absolute;
    top: .75rem;
    left: 1.2rem;
    text-transform: uppercase;
    font-size: rem-calc(12);
    color: $medium-gray;
    letter-spacing: .1em;
  }

  .card-teaser {
    color: $black;
    line-height: 1.5;
    font-size: rem-calc(15);
  }

  & .icon {
    font-size: rem-calc(24);
    color: $medium-gray;
    position: relative;
    top: 7px;
    margin-right: 5px;
    letter-spacing: normal;
  }

  &.article .icon {
    @include icon(before, article);
  }

  &.activity .icon{
    @include icon(before, ruler);
  }

  &.reflection .icon {
    @include icon(before, thinking);
  }

  &.keyword-card .icon, &.glossary .icon {
    @include icon(before, keyword);
  }

  &.video .icon {
    @include icon(before, video);
  }

  &.tipsheet .icon {
    @include icon(before, light-bulb);
  }

  &.lesson-plan .icon {
    @include icon(before, lesson-plan);
  }

  &.guide .icon{
    @include icon(before, guide);
  }

  &.classroom-resource .icon {
    @include icon(before, books-stack);
  }

  &.poster .icon {
    @include icon(before, poster);
  }

  > a {

    &:after {
      background: none;
    }
  }
}
