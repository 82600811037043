@import 'variables';
@import 'mixins';

.edit-link {
  font-size: rem-calc(12);
  text-transform: uppercase;
  letter-spacing: .1em;
  margin: 0 .5rem;
}

.users-filter {
  margin-bottom: 1rem;
  padding-top: 1rem;
  border-top: 1px solid $light-gray;

  > div.large-10.cell {
    @include breakpoint(small only) {
      overflow-x: scroll;
    }
  }

  select {
    margin: 0;
    @include breakpoint(small only) {
      width: 100%;
      margin-top: 2rem;
    }
  }

  .menu {
    @include breakpoint(small only) {
      margin-bottom: 1rem;
      flex-wrap: nowrap;

      li {
        flex: 1 0 auto;
      }
    }

    a {
      color: $black;
      font-weight: 500;
      font-size: rem-calc(14);
    }

    .is-active {

      a {
        position: relative;
        color: $blue;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: $blue;
          bottom: -5px;
          left: 0;
        }
      }
    }
  }
}

.inline-controls {

  select {
    width: auto;
  }

  label {
    display: inline-block;
    margin-right: 1rem;
  }

  .grades-list {
    margin-bottom: 1.5rem;

    input {
      margin-right: 5px;
    }
  }
}

.block-controls {
  margin-bottom: .5rem;

  label {
    display: block;
  }
}

.district-tabs {
  margin-bottom: 0;
}

.ycei-list-header, .district-list-header, .school-list-header {
  margin-top: 2rem;
  margin-bottom: 2rem;

  h2 {
    font-weight: 600;
    font-size: rem-calc(24);
    text-transform: uppercase;
  }

  .search-form {
    @include breakpoint(small only) {
      margin-bottom: 2rem;
    }
  }

  .search-form .search-input {
    border: 1px solid $light-gray;
    border-radius: $global-radius;
    padding-top: .5rem;
    padding-bottom: .5rem;
    height: 2.75rem;
  }

  .filter-menu {
    margin-top: 2rem;

    select {
      margin: 0;
    }
  }
}

.school-list-header {
  margin-top: 0;
}

.district-list-header {
  @include breakpoint(small only) {

    h2 {
      margin-bottom: 1.5rem;
    }
  }
}

.district-details {
  font-size: rem-calc(18);
  font-weight: 300;
}

.button.add-district, .button.add-user, .button.upload-csv, .button.download-activation-links {
  background: $success-color;
  text-transform: uppercase;
  font-size: rem-calc(13);
  font-weight: 600;
  margin: 0;
  @include breakpoint(small only) {
    margin-bottom: 1rem;
  }
}

.reveal.add-school-users, .reveal.upload-csv-users {
  @include breakpoint(large) {
    width: rem-calc(768);
  }
}

.reveal-overlay {

  .reveal.full {
    top: 0;
    padding: 2rem;

    .modal-wrapper .modal-footer .modal-button-wrapper {
      text-align: center;
    }
  }
}

.users-list {
  list-style: none;
  margin: 0;
  font-size: rem-calc(14);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  > li {
    position: relative;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid $light-gray;
    border-radius: $global-radius;
    width: 100%;
    @include breakpoint(medium) {
      width: 49%;
    }
    @include breakpoint(large) {
      width: 100%;
    }
  }

  .grid-x {
    @include breakpoint(460 down) {
      flex-direction: column;
      justify-content: center;
    }
  }

  .user-avatar {
    background: $bg-gray;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border: 1px solid $light-gray;
    border-radius: 50%;

    .avatar-collapsed {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }

    @include breakpoint(medium down) {
      margin-bottom: 1rem;
    }
  }

  .user-name {
    font-weight: 700;
  }

  .user-action {
    display: flex;
    justify-content: space-between;
    @include breakpoint(medium down) {
      margin-top: 1rem;
    }

    .user-status {
      margin-bottom: 0;
      color: $dark-gray;
      font-weight: 600;
    }

    .switch.small .switch-paddle {
      width: 5.25rem;
    }

    .switch.small input:checked ~ .switch-paddle:after {
      left: 3.75rem;
    }

    input:checked + label > .switch-active {
      color: $white;
    }

    .button {
      margin: 0;
      padding: 0;
      font-size: rem-calc(18);
      @include breakpoint(medium down) {
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
    }

    a {
      font-size: rem-calc(12);
      font-weight: 500;
      letter-spacing: .025em;
      text-transform: uppercase;
      padding: 10px 0;

      &:hover {
        color: $alert-color;
      }
    }

    .dropdown-pane {
      width: 150px;
    }
  }
}

.avatar-expanded {
  width: initial !important;
}

.school-users-add-list {
  margin: 1.5rem 0;
}

.user-row {
  margin-bottom: 1.25rem;

  input {
    margin-bottom: .25rem;
  }
}

.district-school-active.switch.small .switch-paddle {
  width: 5.25rem;
}

.district-school-active.switch.small.switch-is-active .switch-paddle:after {
  left: 3.75rem;
}

.district-school-active.switch.small.switch-is-active .switch-active {
  display: block;
}

.district-school-active.switch.small.switch-is-active .switch-inactive {
  display: none;
}

.download-members-container .dropdown-pane li a {

  &:hover {
    color: $alert-color;
  }
}
