.text-uppercase {
  text-transform: uppercase;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.visibility-hidden {
  visibility: hidden;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}
