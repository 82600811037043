@media print {

  .off-canvas-wrapper {
    overflow-y: visible;
  }

  .content-wrap, .main .grid-x, #content {
    display: block !important; // Switch from flex to block for FF multiple pages printing - https://stackoverflow.com/questions/43492724/firefox-prints-first-page-only-but-other-browsers-doing-fine/43494490
  }

  .off-canvas, .header, .footer, .resource-type, .resource-toolbar, .breadcrumb-container-single, .checkpoint-audio {
    display: none;
  }

  .resource-toolbar {
    display: none !important;
  }

  .resource-content .accordion-content {
    display: block !important;
    padding-left: 0;
    // page-break-inside: avoid;
  }

  .accordion-title {
    background: none;
    text-decoration: none;
    padding-left: 0;

    &:after {
      display: none !important;
    }
  }

  .resource-content .accordion .accordion-title:after {
    content: '';
  }

  .single-post-header {
    padding-top: 0;
  }

  .logo-for-print {
    margin-bottom: 2rem;
  }

  .footer-for-print {
    margin-top: 2rem;
  }
}
