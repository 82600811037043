@import 'variables';

.charter-card-list {

  > .cell {
    display: flex;
  }
}

.card.charter-card.expanded {
  transition: all .2s ease;
  margin-bottom: 1.875rem;

  .card-section {
    position: relative;
    padding: 1rem;

    &.meta {
      flex: 0 1 auto;
    }
  }

  .card-date {
    margin: 0;
    font-size: rem-calc(14);
    color: $dark-gray;
    letter-spacing: .0875em;
  }

  .card-title {
    font-size: rem-calc(18);
    font-weight: 600;
    line-height: 1.2;
    padding-right: 1.5rem;
    word-wrap: break-word;
    flex: 1 0 auto;

    a {
      color: inherit;
    }
  }

  .card-author {
    font-size: rem-calc(12);
    text-transform: uppercase;
    letter-spacing: .05em;
    margin-bottom: .5rem;
    color: $dark-gray;
  }

  .card-details {
    font-size: rem-calc(13);
    color: $dark-gray;

    span {
      color: $cloudburst;
      letter-spacing: .075em;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  &.expanded {
    min-height: 236px;
    display: flex;
    flex-direction: row;

    .card-content, .card-image {
      display: flex;
      flex-direction: column;
    }

    .card-content {
      width: 55%;
      padding: 0;
    }

    .card-image {
      width: 45%;
      background: $bg-gray;
      border-left: 1px solid $light-gray;
      padding: 0;

      a {
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.charter-toolbar {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  .button {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 1rem;
    margin: 0 1rem 1rem 0;
    font-size: rem-calc(12);
    font-weight: 600;
    border-width: 2px;
    letter-spacing: .025rem;
    text-transform: uppercase;
    color: $medium-gray;

    &:hover, &:focus {
      color: $blue;
    }

    .text {
      margin-right: 10px;
    }

    .icon {
      font-size: 1rem;
    }

    .icon-share {
      font-size: 1.2rem;
    }

    &.share-charter {
      padding: 0;
    }

    &.pdf-charter .icon-pdf {
      margin-top: 3px;
    }

    &.edit-charter {
      color: $blue;

      &:hover {
        color: $alert-color;
        border-color: $alert-color;
      }
    }
  }
}

.icon-plus {
  background-color: #000;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  width: 16px;
  height: 16px;
  position: relative;
  top: 0;
  left: 0;
}

.charter-meta {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.charter-byline {
  color: $dark-gray;
  margin-bottom: 2rem;

  .date {
    display: block;
    text-transform: uppercase;
    font-size: rem-calc(14);
  }
}

.charter-home {

  .post-media {
    background: $black;
    padding: 4rem 2rem;
    position: relative;
  }
}

.charter-single-container {

  img {
    border: 1px solid $light-gray;
  }
}
