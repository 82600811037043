@font-face {
  font-family: 'rulericons';
  src: url('/static/fonts/rulericons.eot');
  src: url('/static/fonts/rulericons.eot?#iefix') format('eot'),
    url('/static/fonts/rulericons.woff') format('woff'),
    url('/static/fonts/rulericons.ttf') format('truetype'),
    url('/static/fonts/rulericons.svg#rulericons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import 'icons';

[class^='icon-'], [class*='icon-'] {
  @include icon;
}

@each $name, $char in $icons {

  .icon-#{$name} {

    &:before{
      content: $char;
    }
  }
}
