@import 'variables';
@import 'mixins';

.profile-card {
  margin-bottom: 1.75rem;

  .card-icon {
    @include flex;
    @include flex-align(center, middle);
    font-size: rem-calc(52);
    color: $blue;
  }

  &.expanded {
    @include breakpoint(small only) {
      flex-direction: column;
      text-align: center;
      padding-top: 1.5rem;
    }

    > div {
      padding: 1.5rem;
    }

    .card-icon {
      order: 1;
      width: 100%;
      padding: 0;
      @include breakpoint(medium){
        order: 2;
        width: 30%;
      }
    }

    .card-section {
      order: 2;
      width: 100%;
      @include breakpoint(medium) {
        order: 1;
        width: 70%;
      }
    }

    .card-title {
      font-size: rem-calc(24);
      font-weight: bold;
      margin-bottom: .5rem;

      & + p {
        font-weight: 300;
        line-height: 1.35;
      }
    }
  }

  .button {
    border-width: 2px;
    text-transform: uppercase;
    font-size: rem-calc(13);
    font-weight: 600;
    margin-bottom: 0;
    padding: .75rem 2rem;
    letter-spacing: .025em;
  }
}
