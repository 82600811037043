$global-font-size: 100%;
$global-width: rem-calc(1200);
$global-lineheight: 1.5;
$foundation-palette: (
  primary: #0f4d92,
  secondary: #767676,
  success: #33a650,
  warning: #ffae00,
  alert: #ee5f44
);
$light-gray: #c9d3df;
$medium-gray: #8998b5;
$dark-gray: #5c667a;
$bg-gray: #ebeff3;
$black: #000;
$pill-orange: #f29003;
$pill-green: #5fb929;
$check-green-border: #277f3d;
$check-green: #32a751;
$white: #fff;
$blue: #0f4d92;
$cloudburst: #1e2749;
$orange: #ffc552;
$zombie: #e6d39b;
$olive: #6aa329;
$body-background: $white;
$body-font-color: $cloudburst;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 3px;
$global-menu-padding: .7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;

$ruler-blue: #1f477a;
$ruler-green: #5e8542;
$ruler-yellow: #ffc552;
$ruler-red: #ee5f44;

@include add-foundation-colors;
