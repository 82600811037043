@import 'variables';
@import 'mixins';

.choice-group {

  &.sub {

    &:before {
      content: '';
      position: absolute;
      bottom: 1.5rem;
      left: -1rem;
      margin-top: -12px;
      display: inline-block;
      height: 0;
      width: 1.5rem;
      border-bottom: 1px solid #cfd8e3;
      box-sizing: content-box;
    }
  }

  .button-group {
    width: 75%;

    a.button {
      height: 3rem;
      line-height: 2rem;
      padding: .5rem 1rem;
      padding-left: 2.5rem;
      margin-right: .5rem;
      background-color: $white;
      border: 1px solid #d9e0e9;
      color: $black;
      font-weight: 600;
      position: relative;
      text-align: left;
      white-space: nowrap;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        left: 1rem;
        top: 1rem;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .checkmark {
          background-color: $white;
          border: 1px solid #2e7d32;

          &:after {
            display: block;
          }
        }
      }

      .checkmark {
        position: absolute;
        top: 1rem;
        left: 1rem;
        height: 16px;
        width: 16px;
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $light-gray;

        &:after {
          content: '';
          position: absolute;
          display: none;

          top: 3px;
          left: 3px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #247438;
        }
      }

      &:hover input ~ .checkmark {
        background-color: $bg-gray;
      }

      &.inactive {
        color: #9eb0c1;
        background-color: $white;
        cursor: default;
      }

      &.selected:not(.inactive), &.selected:hover:not(.inactive) {
        color: $white;
        background-color: $check-green;
        border: 1px solid $check-green-border;
      }

      &:hover:not(.inactive) {
        color: $cloudburst;
        border: 1px solid $dark-gray;
        background-color: $bg-gray;
      }
    }
  }
}
